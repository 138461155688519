import { Spin, Table, Tag } from 'antd';
import userImg from '../../../assets/img/chamaImg.png';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { customToast, formatMoney } from '../../../utils';
import STKPushSentModal from '../../../components/STKPushSentModal';
import { useDispatch, useSelector } from 'react-redux';
import { mpesaPushSchedule } from '../../../features/money/moneySlice';

export default function ActivityArrearsTable({ data, handleFetch }) {
	const dispatch = useDispatch();

	const { moneying } = useSelector((state) => state.money);

	const [open, setopen] = useState(false);

	function handleCancel() {
		setopen(false);
	}

	async function handleAction(item) {
		const res = await dispatch(mpesaPushSchedule(item));
		console.log(res);

		if (res?.payload?.success) {
			await setopen(true);
		} else {
			customToast({
				content: res?.payload?.messages?.message ?? 'An error occured',
				bdcolor: 'error',
			});
		}
	}
	useEffect(() => {}, [data]);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'schCreatedDate',
			render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
		},
		{
			title: 'Ref No.',
			dataIndex: 'schCode',
		},
		{
			title: 'Source',
			dataIndex: 'schSourceType',
			render: (item) => (
				<>
					<Tag
						icon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='4'
								height='5'
								viewBox='0 0 4 5'
								fill='none'>
								<circle cx='2' cy='2.5' r='2' fill='#121212' />
							</svg>
						}
						color='#F2F4F7'>
						Savings
					</Tag>
				</>
			),
		},
		{
			title: 'Amount (KES)',
			dataIndex: 'schContAmount',
			render: (item) => (
				<button type='button'>{formatMoney(item, 'KES')}</button>
			),
		},
		{
			title: 'Action',
			dataIndex: 'schId',
			render: (item) => (
				<button
					disabled={moneying}
					onClick={() => handleAction(item)}
					type='button'>
					{moneying ? <Spin className='blu_spin' /> : 'Pay'}
				</button>
			),
		},
	];

	return (
		<>
			<Table
				loading={moneying}
				rowKey='schId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				columns={columns}
				dataSource={data}
			/>
			<STKPushSentModal
				handleFetch={handleFetch}
				open={open}
				handleCancel={handleCancel}
			/>
		</>
	);
}
