import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;
const transUrl = process.env.REACT_APP_API_TRANS_URL;

const initialState = {
	loading: false,
	chamas: [],
	members: [],
	statements: [],
	roles: [],
	invitedMembers: [],
	settlementAccounts: [],
};

export const fetchChamas = createAsyncThunk(
	'fetchSlice/fetchChamas',
	async () => {
		const res = await axiosInstance.get(`${url}/api/v1/customers`);
		return res.data.data.result;
	}
);

export const mpesaPush = createAsyncThunk(
	'fetchSlice/mpesaPush',
	async (payObj) => {
		const res = await axiosInstance.get(
			`https://corporate.ndovupay.org/pngp/mpesaPush.action?phone=${payObj?.phone}&amount=${payObj?.amount}&accountref=${payObj?.accountref}`
		);
		return res.data;
	}
);

export const fetchStatements = createAsyncThunk(
	'fetchSlice/fetchStatements',
	async () => {
		const res = await axiosInstance.get(`${transUrl}/api/v2/trans`);
		return res.data.data;
	}
);

export const fetchChamaRoles = createAsyncThunk(
	'fetchSlice/fetchChamaRoles',
	async (id) => {
		const res = await axiosInstance.get(`${url}/api/v1/role/role/${id}`);
		return res.data.data;
	}
);

export const fetchInvitedmembers = createAsyncThunk(
	'fetchSlice/fetchInvitedmembers',
	async (id) => {
		const res = await axiosInstance.get(`${url}/api/v1/invite/${id}`);
		return res.data.data;
	}
);

export const fetchSettlementAccounts = createAsyncThunk(
	'fetchSlice/fetchSettlementAccounts',
	async (id) => {
		const res = await axiosInstance.get(`${url}/api/v1/settlement/${id}`);
		return res.data.data;
	}
);

export const fetchGroupMembers = createAsyncThunk(
	'fetchSlice/fetchGroupMembers',
	async () => {
		const res = await axiosInstance.get(`${url}/api/v1/member`);
		return res.data.data;
	}
);

export const fetchSlice = createSlice({
	name: 'fetch',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(fetchGroupMembers.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchGroupMembers.fulfilled, (state, action) => {
				state.loading = false;
				state.members = action.payload?.result;
			})
			.addCase(fetchGroupMembers.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchSettlementAccounts.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchSettlementAccounts.fulfilled, (state, action) => {
				state.loading = false;
				state.settlementAccounts = action.payload?.result;
			})
			.addCase(fetchSettlementAccounts.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchInvitedmembers.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchInvitedmembers.fulfilled, (state, action) => {
				state.loading = false;
				state.invitedMembers = action.payload?.result;
			})
			.addCase(fetchInvitedmembers.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchChamaRoles.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchChamaRoles.fulfilled, (state, action) => {
				state.loading = false;
				state.roles = action.payload?.result;
			})
			.addCase(fetchChamaRoles.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchStatements.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchStatements.fulfilled, (state, action) => {
				state.loading = false;
				state.statements = action.payload?.result;
			})
			.addCase(fetchStatements.rejected, (state) => {
				state.loading = false;
			})

			.addCase(mpesaPush.pending, (state) => {
				state.loading = true;
			})
			.addCase(mpesaPush.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(mpesaPush.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchChamas.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchChamas.fulfilled, (state, action) => {
				state.loading = false;
				state.chamas = action.payload;
			})
			.addCase(fetchChamas.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default fetchSlice.reducer;
export const {} = fetchSlice.actions;
