import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;
const transUrl = process.env.REACT_APP_API_TRANS_URL;

const initialState = {
	moneying: false,
	schedules: [],
	memberSchedules: [],
	statements: [],
	groupCardSummary: {}
};

export const fetchMemberSchedules = createAsyncThunk(
	'moneySlice/fetchMemberSchedules',
	async () => {
		const res = await axiosInstance
			.get(`${url}/api/v1/schedule/mine`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const mpesaPushSchedule = createAsyncThunk(
	'moneySlice/mpesaPushSchedule',
	async (id) => {
		const res = await axiosInstance.get(`${url}/api/v1/stk/${id}`);
		return res.data;
	}
);

export const fetchGroupCardsSummary = createAsyncThunk(
	'moneySlice/fetchGroupCardsSummary',
	async () => {
		const res = await axiosInstance
			.get(`${url}/api/v1/contr/summary`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchGroupGraph = createAsyncThunk(
	'moneySlice/fetchGroupGraph',
	async () => {
		const res = await axiosInstance
			.get(`${url}/api/v1/schedule/chart`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const moneySlice = createSlice({
	name: 'money',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGroupCardsSummary.pending, (state) => {
				state.moneying = true;
			})
			.addCase(fetchGroupCardsSummary.fulfilled, (state, action) => {
				state.moneying = false;
                state.groupCardSummary = action.payload
			})
			.addCase(fetchGroupCardsSummary.rejected, (state) => {
				state.moneying = false;
			})

            .addCase(mpesaPushSchedule.pending, (state) => {
				state.moneying = true;
			})
			.addCase(mpesaPushSchedule.fulfilled, (state, action) => {
				state.moneying = false;
			})
			.addCase(mpesaPushSchedule.rejected, (state) => {
				state.moneying = false;
			})

            .addCase(fetchMemberSchedules.pending, (state) => {
				state.moneying = true;
			})
			.addCase(fetchMemberSchedules.fulfilled, (state, action) => {
				state.moneying = false;
				state.memberSchedules = action.payload;
			})
			.addCase(fetchMemberSchedules.rejected, (state) => {
				state.moneying = false;
			})
	},
});

export default moneySlice.reducer;
export const {} = moneySlice.actions;
