import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import userImg from '../assets/svg/user.svg';
import chamaImg from '../assets/img/chamaImg.png';

export default function SideBarOpen() {
	const navigate = useNavigate();

	const { collapsed } = useSelector((state) => state.setup);
	const { user } = useSelector((state) => state.auth);
	const { chamaActive, activeChama } = useSelector((state) => state.obj);

	const handleNavigation = (e) => {
		navigate(e.key);
	};

	const items = chamaActive
		? [
				{
					key: '2',
					icon: (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='21'
							viewBox='0 0 20 21'
							fill='none'>
							<path
								d='M16.0109 11.047H15.9626L15.9411 11.0902L12.9895 16.9949C12.9895 16.9949 12.9895 16.9949 12.9895 16.9949C12.944 17.0858 12.8742 17.1622 12.7877 17.2155C12.7013 17.2689 12.6017 17.2971 12.5001 17.297H12.5H12.4707C12.3653 17.2915 12.2638 17.2555 12.1783 17.1935C12.0924 17.1311 12.0265 17.045 11.9886 16.9458L7.52223 5.21923L7.45558 5.04425L7.3781 5.21471L4.87263 10.7264L4.87263 10.7264C4.82919 10.822 4.75917 10.903 4.67093 10.9599C4.58269 11.0167 4.47996 11.047 4.375 11.047C4.37499 11.047 4.37498 11.047 4.37497 11.047H1.875C1.72996 11.047 1.59086 10.9894 1.4883 10.8869C1.38574 10.7843 1.32812 10.6452 1.32812 10.5002C1.32812 10.3551 1.38574 10.216 1.4883 10.1135C1.59086 10.0109 1.72996 9.95329 1.875 9.95329H3.97266H4.02296L4.04378 9.90749L7.00237 3.3989L7.00238 3.39887C7.04701 3.3006 7.11973 3.21773 7.21137 3.1607C7.303 3.10367 7.40947 3.07503 7.51735 3.07839C7.62524 3.08175 7.72971 3.11696 7.81762 3.17959C7.90553 3.24222 7.97294 3.32945 8.01137 3.43032L12.5043 15.2272L12.5677 15.3935L12.6472 15.2343L15.1355 10.257L15.1356 10.2568C15.1809 10.1657 15.2507 10.089 15.3371 10.0354C15.4236 9.98178 15.5233 9.95334 15.625 9.95329H18.125C18.27 9.95329 18.4091 10.0109 18.5117 10.1135C18.6143 10.216 18.6719 10.3551 18.6719 10.5002C18.6719 10.6452 18.6143 10.7843 18.5117 10.8869C18.4091 10.9894 18.27 11.047 18.125 11.047H16.0109Z'
								fill='#FFCD47'
								stroke='#FFCD47'
								strokeWidth='0.15625'
							/>
						</svg>
					),
					label: 'Group activity',
					children: [
						{
							label: 'Overview',
							key: '/group/overview',
						},
						{
							label: 'Statements',
							key: '/group-activity/statements',
						},
						{
							label: 'Accounts summary',
							key: '/accounts/summary',
						},
						{
							label: 'Loans',
							key: '3',
							disabled: true,
							children: [
								{
									label: 'Loan summary',
									key: '/loans/summary',
								},
								{
									label: 'Loan requests',
									key: '/loans/requests',
								},
							],
						},
						// {
						// 	label: 'Contributions',
						// 	key: '5',
						// 	disabled: true,
						// 	children: [
						// 		{
						// 			label: 'Group statements',
						// 			key: '/contributions/group-statements',
						// 		},
						// 		{
						// 			label: 'Member statements',
						// 			key: '/contributions/member-statements',
						// 		},
						// 		{
						// 			label: 'Group contribution report',
						// 			key: '/contributions/group-report',
						// 		},
						// 	],
						// },
					],
				},
				{
					key: '6',
					icon: (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='21'
							viewBox='0 0 20 21'
							fill='none'>
							<path
								d='M0.832031 17.1667V16.3333C0.832031 13.1117 3.44371 10.5 6.66536 10.5C9.88703 10.5 12.4987 13.1117 12.4987 16.3333V17.1667'
								stroke='#FFCD47'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M10.832 12.1667C10.832 9.8655 12.6975 8 14.9987 8C17.2999 8 19.1654 9.8655 19.1654 12.1667V12.5833'
								stroke='#FFCD47'
								strokeWidth='1.5'
								strokeLinecap='round'
							/>
							<path
								d='M6.66536 10.4997C8.50628 10.4997 9.9987 9.00726 9.9987 7.16634C9.9987 5.32539 8.50628 3.83301 6.66536 3.83301C4.82441 3.83301 3.33203 5.32539 3.33203 7.16634C3.33203 9.00726 4.82441 10.4997 6.66536 10.4997Z'
								stroke='#FFCD47'
								strokeWidth='1.5'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M15 8C16.3807 8 17.5 6.88071 17.5 5.5C17.5 4.11929 16.3807 3 15 3C13.6192 3 12.5 4.11929 12.5 5.5C12.5 6.88071 13.6192 8 15 8Z'
								stroke='#FFCD47'
								strokeWidth='1.5'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					),
					label: 'Members management',
					children: [
						{
							label: 'Overview',
							key: '/members/overview',
						},
						{
							label: 'Member balances',
							key: '/members/balance',
						},
						{
							label: 'Penalties',
							key: '/members/penalties',
						},
						{
							label: 'Arrears',
							key: '/members/arrears',
						},
					],
				},
				{
					key: '4',
					icon: (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='21'
							viewBox='0 0 20 21'
							fill='none'>
							<g clipPath='url(#clip0_863_35748)'>
								<path
									d='M13.25 4.95076C13.25 6.71849 11.7949 8.15152 10 8.15152C8.20507 8.15152 6.75 6.71849 6.75 4.95076C6.75 3.18303 8.20507 1.75 10 1.75C11.7949 1.75 13.25 3.18303 13.25 4.95076Z'
									stroke='#FFCD47'
									strokeWidth='1.50926'
									strokeLinejoin='round'
								/>
								<path
									d='M3.75 16.3996C3.75 13.748 5.93261 11.5985 8.625 11.5985H11.375C14.0674 11.5985 16.25 13.748 16.25 16.3996C16.25 17.2835 15.5225 18 14.625 18H5.375C4.47754 18 3.75 17.2835 3.75 16.3996Z'
									stroke='#FFCD47'
									strokeWidth='1.50926'
									strokeLinejoin='round'
								/>
							</g>
							<defs>
								<clipPath id='clip0_863_35748'>
									<rect
										width='20'
										height='20'
										fill='white'
										transform='translate(0 0.5)'
									/>
								</clipPath>
							</defs>
						</svg>
					),
					label: 'Your activity',
					children: [
						{
							label: 'Overview',
							key: '/activity/overview',
						},
						{
							label: 'Statements',
							key: '/activity/statements',
						},
						{
							label: 'Loans',
							key: '/activity/loans',
						},
						{
							label: 'Penalties',
							key: '/activity/penalties',
						},
						{
							label: 'Arrears',
							key: '/activity/arrears',
						},
					],
				},
		  ]
		: [
				{
					key: '1',
					icon: <img src={userImg} alt='user' />,
					label: 'Home',
					children: [
						{
							label: 'Chama',
							key: '/home',
						},
						{
							label: 'Personal investemet',
							key: '/personal/investment',
						},
					],
				},
		  ];

	useEffect(() => {}, [collapsed, chamaActive, activeChama]);

	return (
		<>
			<div
				style={{
					borderRadius: '0.875rem 0.875rem 0rem 0rem',
				}}
				className='flex flex-col h-[100vh] overflow-y-auto flex-shrink-0 w-[15.75rem] bg-white'>
				{chamaActive ? (
					<>
						<div className='w-full max-w-sm bg-mainBg rounded-lg'>
							<div className='card'>
								<div className='cover-photo'>
									<img
										loading='lazy'
										style={{
											zIndex: '1000',
										}}
										src={chamaImg}
										alt='chamaImg'
										className='profile z-40 rounded'
									/>
								</div>

								<div className='flex flex-col items-center pb-[2.19rem]'>
									<span className='heading_5 mt-[19%]'>
										{activeChama?.custName}
									</span>
									<span className='profile_email mt-[.81rem] !text-[#98A2B3]'>
										Joined 12th June 2024
									</span>

									<div
										style={{
											padding: '0.375rem 0rem 0.5625rem 0rem',
										}}
										className='rounded-[2.5rem] bg-[#F5F5F5] w-[8.25rem] flex justify-center items-center mt-[1.25rem]'>
										<span className='label_4'>
											{activeChama?.custNumber} members
										</span>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className='w-full max-w-sm bg-mainBg rounded-lg'>
							<div className='card'>
								<div className='cover-photo'>
									<img
										loading='lazy'
										style={{
											zIndex: '1000',
										}}
										src={
											user?.usrProfileImage
												? ''
												: 'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png'
										}
										alt='profile'
										className='profile z-40 rounded'
									/>
								</div>

								<div className='flex flex-col items-center pb-[2.19rem]'>
									<span className='heading_5 mt-[19%]'>{user?.userName}</span>
									<span className='profile_email mt-[1.44rem]'>
										{user?.email}
									</span>
									<span className='paragraph_2 mt-[.94rem]'>
										+254 712 345 678
									</span>
								</div>
							</div>
						</div>
					</>
				)}

				<Menu
					onClick={handleNavigation}
					style={{
						width: '100%',
					}}
					mode='inline'
					items={items}
					theme='light'
					inlineCollapsed={collapsed}
				/>
			</div>
		</>
	);
}
