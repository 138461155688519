import React, { useEffect } from 'react';
import {
	BankOutlined,
	UsergroupAddOutlined,
	AccountBookOutlined,
	SnippetsOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import image10 from '../assets/svg/ndovuLogo.svg';


const items = [
	{
		key: '/home',
		icon: <BankOutlined />,
		label: 'Home',
	},
	{
		key: '/members',
		icon: <UsergroupAddOutlined />,
		label: 'Members',
	},
	{
		key: '/receipts',
		icon: <AccountBookOutlined />,
		label: 'Receipts',
	},
	{
		key: '/reports',
		icon: <SnippetsOutlined />,
		label: 'Reports',
	},
];

export default function SideBarClosed() {
	const { collapsed } = useSelector((state) => state.setup);	

	useEffect(() => {}, [collapsed]);

	return (
		<>
			<div
				style={{
					borderInlineEnd: '1px solid rgba(5, 5, 5, 0.06)',
					padding: '0.75rem',
				}}
				className='flex flex-col items-center h-[100vh] bg-white sticky top-0'>
				<div
					style={{
						height: '3.75rem',
						display: 'flex',
						alignItems: 'center',
					}}
					className='flex'>
					<div className='flex items-center'>
						<img src={image10} className='logo' alt='image2' />
					</div>
				</div>

				<Menu
					defaultSelectedKeys={['dash', '/home']}
					defaultOpenKeys={[]}
					mode='inline'
					theme='light'
					inlineCollapsed={Number(window.innerWidth) < 1024 ? true : false}
					items={items}
				/>
			</div>
		</>
	);
}
