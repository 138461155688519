import { Checkbox, Divider, Form, Input, Select, Space, Spin } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileUploader from '../../../../components/FileUploader';
import TextArea from 'antd/es/input/TextArea';
import { applicationUrl } from '../../../../utils';
import { handleNewChamaData } from '../../../../features/obj/objSlice';

export default function GroupDetailsSubStepOne({ handleActiveStep, form }) {
	const dispatch = useDispatch();

	const { newChamaData } = useSelector((state) => state.obj);
	const { saving } = useSelector((state) => state.save);

	const [signatories, setsignatories] = useState(
		newChamaData?.custGroupMandate ?? null
	);

	const [fileObj, setfileObj] = useState({
		custRegistrationCertificate: newChamaData?.custRegistrationCertificate ?? '',
		custSignedMemorandum: newChamaData?.custSignedMemorandum ?? '',
	});

	async function handleSubmitStep() {        
		try {
			await form.validateFields([
				'custName',
				'custGroupDescription',
				'custGroupMandate',
			]);
            handleActiveStep(1)
		} catch {}
	}

	const handleSuccess = (response) => {        
		setfileObj((prev) => ({
			...prev,
			[response.fileName]: response?.data?.result,
		}));
		dispatch(handleNewChamaData({
			[response.fileName]: response?.data?.result,
		}));
	};

	return (
		<>
			<div className='flex flex-col w-full'>
				<Form.Item
					label='Group name'
					name='custName'
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
					]}>
					<Input className='input' />
				</Form.Item>

				<Form.Item
					label='Business registration no.'
					extra='Optional'
					name='custBusinessRegistrationNo'>
					<Input className='input' />
				</Form.Item>

				<Form.Item label='Registration Certificate' extra='Optional'>
					<FileUploader
						fileName={fileObj.custRegistrationCertificate?.split('_').pop()}
						name='custRegistrationCertificate'
						onSuccess={handleSuccess}
					/>
				</Form.Item>

				<Form.Item
					label='Group description'
					name='custGroupDescription'
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
					]}>
					<TextArea rows={5} className='text_area' />
				</Form.Item>

				<Form.Item label='Signed memorandum' extra='Optional'>
					<FileUploader
						fileName={fileObj.custSignedMemorandum?.split('_').pop()}
						name='custSignedMemorandum'
						onSuccess={handleSuccess}
					/>
				</Form.Item>

				<Form.Item
					label='Group mandate'
					name='custGroupMandate'
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
					]}>
					<Select
						onChange={(val) => setsignatories(val)}
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='21'
								height='21'
								viewBox='0 0 21 21'
								fill='none'>
								<path
									d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
									fill='#212121'
								/>
							</svg>
						}
						dropdownRender={(menu) => (
							<>
								<Space
									style={{
										padding: '.62rem 1rem',
									}}>
									<span className='select_title'>
										Select number of signatories needed to approve outgoing
										transactions
									</span>
								</Space>
								<Divider
									style={{
										margin: '8px 0',
									}}
								/>
								{menu}
							</>
						)}
						options={[2, 3].map((item, index) => {
							return {
								label: (
									<div key={index}>
										<Checkbox checked={signatories === item}>
											At least {item} signatories
										</Checkbox>
									</div>
								),
								value: item,
							};
						})}
					/>
				</Form.Item>

				<div className='py-7 w-full flex justify-center'>
					<button
						disabled={saving}
						onClick={handleSubmitStep}
						type='button'
						className='cstm-btn-2 !w-[200px]'>
						{saving ? <Spin /> : 'Next'}
					</button>
				</div>
			</div>
		</>
	);
}
