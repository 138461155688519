import { Table, Tag } from 'antd';
import moment from 'moment';

const columns = [
	{
		title: 'Date',
		dataIndex: 'date',
		render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
	},
	{
		title: 'Activity',
		dataIndex: 'trxRef',
		render: (item) => (
			<>
				<Tag
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='4'
							height='5'
							viewBox='0 0 4 5'
							fill='none'>
							<circle cx='2' cy='2.5' r='2' fill='#121212' />
						</svg>
					}
					color='#F6F6F6'>
					{item}
				</Tag>
			</>
		),
	},
	{
		title: 'Ref no.',
		dataIndex: 'seats',
	},
	{
		title: 'Debit (KES)',
		dataIndex: 'seats',
	},
	{
		title: 'Credit (KES)',
		dataIndex: 'seats',
	},
	{
		title: 'Running balance (KES)',
		dataIndex: 'seats',
	},
];

export default function ActivityRecentTransactionTable() {
	return (
		<>
			<Table
				rowSelection={true}
				rowKey='trxId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				scroll={{
					x: 1200,
				}}
				columns={columns}
				dataSource={[]}
			/>
		</>
	);
}
