export const emails = [
	{
		id: 1,
		sender: 'Beta World Devs',
		hasFile: true,
		subject: 'Subject 1',
		body: 'This is sparta version 1',
		date: new Date(),
		read: false,
	},
	{
		id: 2,
		sender: 'House Inn Management',
		hasFile: false,
		subject: 'Subject 2',
		body: 'This is sparta version 2',
		date: new Date(),
		read: true,
	},
	{
		id: 3,
		sender: 'House Inn Admin',
		hasFile: true,
		subject: 'Subject 3',
		body: 'This is sparta version 3',
		date: new Date(),
		read: true,
	},
];

export const collectionIntervals = [
	{
		label: 'Daily',
		value: 'DAILY',
	},
	{
		label: 'Weekly',
		value: 'WEEKLY',
	},
	{
		label: 'Bi-weekly (Every two weeks)',
		value: 'BiWEEKLY',
	},
	{
		label: 'Monthly',
		value: 'MONTHLY',
	},
	{
		label: 'Quarterly',
		value: 'QUARTERLY',
	},
	{
		label: 'Every 6 months',
		value: 'HALF_YEAR',
	},
	{
		label: 'Yearly',
		value: 'YEARLY',
	},
];

export const roleTypes = [
	{
		label: 'Chairman',
		value: 'Chairman',
	},
	{
		label: 'Treasurer',
		value: 'Treasurer',
	},
	{
		label: 'Secretary',
		value: 'Secretary',
	},
	{
		label: 'Member',
		value: 'Member',
	},
];

export const reportType = [
	{
		label: 'Member',
		value: 'INDIVIDUAL',
	},
	{
		label: 'Chama Group',
		value: 'ORGANIZATION',
	},
];

export const booleanType = [
	{
		label: 'Yes',
		value: true,
	},
	{
		label: 'No',
		value: false,
	},
];

export const banksData = [
	{
		label: 'Equity',
		value: 'Equity',
	},
	{
		label: 'KCB',
		value: 'KCB',
	},
	{
		label: 'CO-OP',
		value: 'CO-OP',
	},
];

export const statementsData = [
	{
		id: 1,
		date: '12th July 2024',
		activity: 'Contribution',
		member: 'Jeremy Neigh',
		refNo: 'K123467',
		debit: 2000,
		credit: 5000,
		runningBalance: 200,
	},
];
