import { Badge, Dropdown, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
	CaretDownOutlined,
	LogoutOutlined,
	UserSwitchOutlined,
} from '@ant-design/icons';
import { clearObj, setActiveChama } from '../features/obj/objSlice';
import { logout } from '../features/auth/authSlice';
import image10 from '../assets/svg/ndovuLogo.svg';
import userImg from '../assets/img/user.png';
import { useNavigate } from 'react-router-dom';

export default function SubHeader() {
	const dispatch = useDispatch();
	const navigate = useNavigate()

	const { collapsed } = useSelector((state) => state.setup);
	const { user } = useSelector((state) => state.auth);
	const { myInstitutions } = useSelector((state) => state.fetch);
	const { activeChama } = useSelector((state) => state.obj);

	const [open, setopen] = useState(false);
	const [open1, setopen1] = useState(false);

	function handleOpenChange() {
		setopen(!open);
	}

	function handleOpenChange1() {
		setopen1(!open1);
	}

	async function handleLogout() {
		await dispatch(clearObj());
		await dispatch(logout());
		await localStorage.clear();
		await navigate("/login")
	}

	async function handleSetActiveChama(item) {
		await dispatch(setActiveChama(item));
		await handleOpenChange();
	}

	const profileDropDownItems = [
		{
			label: (
				<>
					<div className='w-[20rem] mb-[2rem]'>
						<div className='w-full mt-[.5rem] flex flex-col px-[.75rem] py-[1.5rem] gap-y-[.5rem] rounded-[1rem]'>
							<span className='heading_4 !text-[#1E191B] mb-[1rem]'>
								My Chama Accounts
							</span>

							{myInstitutions?.length ? (
								<>
									{myInstitutions?.map((item, index) => {
										return (
											<div
												onClick={() => handleSetActiveChama(item)}
												key={index}>
												<div className='flex items-center gap-[.5rem] p-[.75rem] rounded-[1rem] border border-[#FFAB00]'>
													<div className='flex flex-col w-full'>
														<span className='paragraph_1 !text-[#1E191B]'>
															{item?.orgName}
														</span>

														<div className='w-full flex items-center gap-[1rem]'>
															<span className='accounts_txt'>
																{item?.orgBusinessType}
															</span>

															<span className='accounts_txt'>
																{item?.orgPostalAddress} {item?.orgMobile}
															</span>
														</div>
														{activeChama?.orgId === item?.orgId ? (
															<Tag className='status' color='green'>
																<span className='status_txt'>Active</span>
															</Tag>
														) : null}
													</div>
												</div>
											</div>
										);
									})}
								</>
							) : (
								<>
									<span>All you chama groups will appear here</span>
								</>
							)}

							<div className='w-full flex flex-col items-center justify-center mt-[3rem]'>
								<button onClick={handleLogout} className='cstm-btn'>
									<LogoutOutlined className='text-[25px] text-white' />
									<span className='ml-[.5rem]'>Logout</span>
								</button>
							</div>
						</div>
					</div>
				</>
			),
			key: '0',
		},
	];

	const notificationDropDownItems = [
		{
			label: (
				<>
					<span>No new notification</span>
				</>
			),
			key: '0',
		},
	];

	useEffect(() => {}, [collapsed, open]);

	return (
		<>
			<div className='w-full bg-white flex items-center justify-between px-[2.12rem] py-[1rem]'>
				<div className='flex items-center'>
					<div className='flex items-center w-full gap-[.25rem]'>
						<img src={image10} className='logo !w-fit h-full' alt='image2' />
						<span className='heading_4'>Ndovu chama.</span>
					</div>
				</div>

				<div className='flex items-center gap-[1.5rem]'>
					<button className='mr-5 flex items-center'>
						<Dropdown
							overlayClassName='avatar_dropdown'
							onOpenChange={handleOpenChange1}
							arrow
							open={open1}
							menu={{
								items: notificationDropDownItems,
							}}
							trigger={['click']}
							placement='bottom'>
							<div className='flex items-center'>
								<Badge count={9} overflowCount={99}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='32'
										height='32'
										viewBox='0 0 32 32'
										fill='none'>
										<path
											d='M22.2213 18.3758L21.3333 17.5812V10.6665C21.3333 9.25202 20.7714 7.89546 19.7712 6.89527C18.771 5.89507 17.4145 5.33317 16 5.33317C14.5855 5.33317 13.229 5.89507 12.2288 6.89527C11.2286 7.89546 10.6667 9.25202 10.6667 10.6665V17.5812L9.77867 18.3758C8.49007 19.5258 7.5465 21.0113 7.05334 22.6665H24.9467C24.4535 21.0113 23.5099 19.5258 22.2213 18.3758ZM20 25.3332C20 26.394 19.5786 27.4115 18.8284 28.1616C18.0783 28.9117 17.0609 29.3332 16 29.3332C14.9391 29.3332 13.9217 28.9117 13.1716 28.1616C12.4214 27.4115 12 26.394 12 25.3332H4.00001C3.99819 23.6446 4.35366 21.9747 5.04305 20.4332C5.73244 18.8917 6.74016 17.5135 8.00001 16.3892V10.6665C8.00001 8.54477 8.84286 6.50994 10.3432 5.00965C11.8434 3.50936 13.8783 2.6665 16 2.6665C18.1217 2.6665 20.1566 3.50936 21.6569 5.00965C23.1572 6.50994 24 8.54477 24 10.6665V16.3892C25.2599 17.5135 26.2676 18.8917 26.957 20.4332C27.6464 21.9747 28.0018 23.6446 28 25.3332H20ZM16 26.6665C16.3536 26.6665 16.6928 26.526 16.9428 26.276C17.1929 26.0259 17.3333 25.6868 17.3333 25.3332H14.6667C14.6667 25.6868 14.8071 26.0259 15.0572 26.276C15.3072 26.526 15.6464 26.6665 16 26.6665Z'
											fill='#121212'
										/>
									</svg>
								</Badge>
							</div>
						</Dropdown>
						<span className='ml-[.75rem] text-[#1E191B] font-semibold'>
							{user?.usrFirstName} {user?.usrLastName}
						</span>
					</button>

					<button className='mr-5 flex items-center'>
						<Dropdown
							overlayClassName='avatar_dropdown'
							onOpenChange={handleOpenChange}
							arrow
							open={open}
							menu={{
								items: profileDropDownItems,
							}}
							trigger={['click']}
							placement='bottom'>
							<div className='flex items-center'>
								<div className='flex justify-center items-center p-[.4rem]'>
									<img src={userImg} alt="user" className='w-[2.5rem] h-[2.5rem] object-cover rounded-full' />
								</div>
							</div>
						</Dropdown>
						<span className='ml-[.75rem] text-[#1E191B] font-semibold'>
							{user?.usrFirstName} {user?.usrLastName}
						</span>
					</button>
				</div>
			</div>
		</>
	);
}
