export default function ProTips() {
	return (
		<>
			<div className='w-full h-[8rem] bg-[#E8F0FF] flex justify-between items-center pl-[2.5rem] pr-[1.5rem] rounded-[1.25rem]'>
				<div className='w-full flex flex-col gap-[.75rem]'>
					<span className='label_1'>
						Pro tip | Grow your personal investments
					</span>
					<span className='paragraph_1'>
						You can boost your financial growth by opening an investment account
					</span>
				</div>

				<div className='w-fit'>
					<button className='cstm-btn-2 !bg-[#17225F]'>Invest</button>
				</div>
			</div>
		</>
	);
}
