import { createHashRouter } from 'react-router-dom';
import Login from './pages/auth/Login';
import { ProtectedRoute } from './ProtectedRoute';
import Root from './Root';
import Register from './pages/auth/Register';
import Onboarding from './pages/onboarding/Onboarding';
import { OnboardingRoute } from './OnboardingRout';
import OnboardingOptions from './pages/onboarding/OnboardingOptions';
import MainHome from './pages/home/MainHome';
import Statements from './pages/statements/Statements';
import { FullPageRoute } from './FullPageRoute';
import NewChama from './pages/chama/NewChama';
import ChamaFeatures from './pages/chama/ChamaFeatures';
import ActivutyOverview from './pages/activities/ActivityOverview';
import GroupStatement from './pages/contributions/GroupStatement';
import MemberStatement from './pages/contributions/MemberStateMent';
import GroupOverview from './pages/group/GroupOverview';
import MemberBalance from './pages/members/MembersBalance';
import GroupContributionHistory from './pages/group/GroupContributionHistory';
import MembersOverview from './pages/members/MembersOverview';
import MembersPenalties from './pages/members/MembersPenalties';
import MembersArrears from './pages/members/MembersArrears';
import AccountSummary from './pages/accounts/AccountSummary';
import ActivityHistory from './pages/activities/ActivityHistory';
import ActivityStatement from './pages/activities/ActivityStatement';
import ActivityArrears from './pages/activities/ActivtyArrears';
import NewUserInvite from './pages/invites/NewUserInvite';
import MemberDetails from './pages/members/MemberDetails';

export const router = createHashRouter([
	{
		path: '/',
		element: <Root />,
		children: [
			{
				path: '/',
				element: <Login />,
				index: true,
			},
			{
				path: '/login',
				element: <Login />,
			},
			{
				path: '/registration',
				element: <Register />,
			},
			{
				path: '/new-user/chama-invite',
				element: <NewUserInvite />,
			},
			{
				element: <OnboardingRoute />,
				children: [
					{
						path: '/onboarding',
						element: <Onboarding />,
					},
					{
						path: '/onboarding-options',
						element: <OnboardingOptions />,
					},
					{
						path: '/chama-features',
						element: <ChamaFeatures />,
					},
				],
			},
			{
				element: <FullPageRoute />,
				children: [
					{
						path: '/new/chama-features',
						element: <ChamaFeatures />,
					},
					{
						path: '/new/chama-group',
						element: <NewChama />,
					},
					// {
					// 	path: '/new-user/chama-invite',
					// 	element: <NewUserInvite />,
					// },
				],
			},
			{
				element: <ProtectedRoute />,
				children: [
					{
						path: '/home',
						element: <MainHome />,
					},
					{
						path: '/group-activity/statements',
						element: <Statements />,
					},
					{
						path: '/group/overview',
						element: <GroupOverview />,
					},
					{
						path: '/group/contribution-history',
						element: <GroupContributionHistory />,
					},
					{
						path: '/activity/overview',
						element: <ActivutyOverview />,
					},
					{
						path: '/activity/history',
						element: <ActivityHistory />,
					},
					{
						path: '/activity/statements',
						element: <ActivityStatement />,
					},
					{
						path: '/activity/arrears',
						element: <ActivityArrears />,
					},
					{
						path: '/contributions/group-statements',
						element: <GroupStatement />,
					},
					{
						path: '/contributions/member-statements',
						element: <MemberStatement />,
					},
					{
						path: '/members/overview',
						element: <MembersOverview />,
					},
					{
						path: '/members/balance',
						element: <MemberBalance />,
					},
					{
						path: '/members/penalties',
						element: <MembersPenalties />,
					},
					{
						path: '/members/arrears',
						element: <MembersArrears />,
					},
					{
						path: '/members/:name',
						element: <MemberDetails />,
					},
					{
						path: '/accounts/summary',
						element: <AccountSummary />,
					},
				],
			},
			{
				path: '*',
				element: <p>404 - Error: Page not found</p>,
			},
		],
	},
]);
