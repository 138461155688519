import { Table } from 'antd';
import moment from 'moment';
import React from 'react'
import userImg from '../../../assets/img/chamaImg.png';

export default function MemberPendingInviteTable() {

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
            sorter: (a, b) => a - b,
        },
        {
            title: 'Name',
            dataIndex: 'member',
            render: (item) => (
                <>
                    <div className='w-full flex items-center gap-[.5rem]'>
                        <img
                            src={userImg}
                            className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
                            alt='avatar'
                        />
                        <span>Member Name</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'trxRef',
        },
        {
            title: 'Email',
            dataIndex: 'trxRef',
        },
        {
            title: 'Status',
            dataIndex: 'trxRef',
        },
        {
            title: 'Action',
            dataIndex: 'trxRef',
        },
    ];

  return (
    <div>

			<Table
				rowSelection={true}
				rowKey='trxId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				columns={columns}
				dataSource={[]}
			/>

    </div>
  )
}
