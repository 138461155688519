import Search from '../../../components/Search';
import StatementFilter from '../../statements/StatementFilter';
import MemberStatementTable from '../tables/MemberStatementTable';

export default function MemberStatementTab() {
	return (
		<>
			<div className='mt-[2.5rem] w-full flex flex-col !pb-[5rem] p-[1.25rem]'>
				<span className='heading_4 mt-[2.5rem]'>Ben’s statements</span>
				<div className='flex items-center gap-[1rem] w-full mt-[1.25rem]'>
					<div className='w-[24.1875rem] '>
						<Search text={'Search ref no., activity or date'} />
					</div>
					<div className='w-[13.625rem] '>
						<StatementFilter />
					</div>
				</div>

				<MemberStatementTable />
			</div>
		</>
	);
}
