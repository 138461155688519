import { Area } from '@ant-design/plots';
import { Select } from 'antd';
import useFetch from '../../../custom_hooks/useFetch';
import { applicationUrl } from '../../../utils';
import moment from 'moment';

export default function AnalyticGraph() {
	const { data, isLoading } = useFetch(
		`${applicationUrl().url}/api/v1/schedule/chart`
	);

	const config = {
		data: data,
		loading: isLoading,
		xField: (d) => moment(d?.schCreatedDate).format(d?.schType === "DAILY" ? 'Do' : 'MMM'),
		yField: (d) => (d?.schContAmount),
		// yField: 'amount',
		colorField: 'schStatus',
		// normalize: true,
		stack: true,
		tooltip: false,
		line: {
			style: {
				stroke: '#121212',
				strokeWidth: 2,
			},
		},
	};

	return (
		<>
			<div className='flex flex-col w-full'>
				<div className='flex items-center justify-between'>
					<span className='heading_4'>Analytics</span>
					<div className='flex items-center gap-[2rem]'>
						<div className='flex items-center gap-[.75rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'>
								<circle cx='6' cy='6' r='6' fill='#8EC68E' />
							</svg>
							<span className='graph_icon_text'>Collections</span>
						</div>
						<div className='flex items-center gap-[.75rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='12'
								height='12'
								viewBox='0 0 12 12'
								fill='none'>
								<circle cx='6' cy='6' r='6' fill='#FFB07C' />
							</svg>
							<span className='graph_icon_text'>Arrears</span>
						</div>

						<Select
							suffixIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='17'
									height='18'
									viewBox='0 0 17 18'
									fill='none'>
									<path
										d='M11.5404 7.08203L8.49949 10.1229L5.45861 7.08203L4.45703 8.08361L8.49949 12.1261L12.5419 8.08361L11.5404 7.08203Z'
										fill='#0D163A'
									/>
								</svg>
							}
							className='graph_select'
							placeholder='Monthly'
						/>
					</div>
				</div>
				<div className='mt-[2.13rem] h-[10rem]'>
					<Area {...config} />
				</div>
			</div>
		</>
	);
}
