import { useEffect } from 'react';
import ProTips from '../../components/ProTips';
import UserBanner from '../../components/UserBanner';
import ChamaList from './ChamaList';
import { useDispatch } from 'react-redux';
import { clearObj } from '../../features/obj/objSlice';

export default function MainHome() {
	const dispatch = useDispatch()

	async function handleInit() {
		await dispatch(clearObj())
	}

	useEffect(() => {
		handleInit()
	}, [])
	
	return (
		<>
			<div className='grid grid-cols-1 gap-[1.25rem]'>
				<UserBanner />
				<ProTips />
                <ChamaList />
			</div>
		</>
	);
}
