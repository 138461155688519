import { useEffect, useState } from 'react';
import { handleLogout } from '../instance';

export default function useFetch(url) {
	const [isLoading, setisLoading] = useState(false);
	const [isError, setisError] = useState('');
	const [data, setdata] = useState([]);

	const token = localStorage.getItem('token');

	useEffect(() => {
		const fetchData = async () => {
			const res = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});
			try {
				if (!res.ok) {
					console.log("RES", res);
					if (res.status === 403) {
						handleLogout()
					}
					setisError('');
					setisLoading(false);
					return;
				}

				const response = await res.json();
				setdata(response?.data?.result);
				setisError('');
			} catch (error) {
				setisError('');
				console.log("Catch", error);
				
			}
			setisLoading(false);
		};
		fetchData();
	}, [url, token]);
	return { isLoading, isError, data };
}
