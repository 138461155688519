import Dragger from 'antd/es/upload/Dragger';
import { useState } from 'react';
import { customToast } from '../utils';

const url = process.env.REACT_APP_API_BASE_URL;

export default function FileUploader({ name, onSuccess, fileName }) {
	const [fileUploaded, setfileUploaded] = useState('');

	const props = {
		name: 'file',
		showUploadList: false,
		multiple: false,
		accept: 'image/png, image/jpeg, .pdf',
		action: `${url}/v1/api/att`,
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
			}
			if (status === 'done') {
				if (info?.file?.response?.success) {
					const data = { ...info?.file?.response, fileName: name };
					onSuccess(data);
					setfileUploaded(info.file.name);
					customToast({
						content: `${info.file.name} file uploaded.`,
						bdColor: 'success',
						id: 3,
					});
				} else {
					customToast({
						content: `${info.file.name} file upload failed.`,
						bdColor: 'error',
						id: 1,
					});
				}
			} else if (status === 'error') {
				customToast({
					content: `${info.file.name} file upload failed.`,
					bdColor: 'error',
					id: 2,
				});
			}
		},
		onDrop(e) {
			// console.log('Dropped files', e.dataTransfer.files);
		},
	};

	return (
		<>
			<Dragger className='input-dragger' {...props}>
				<div className='flex justify-between items-center'>
					{fileUploaded || fileName ? (
						<span className='w-[150px] truncate !text-left'>
							{fileUploaded || fileName}
						</span>
					) : null}
					<div className='ml-auto'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='21'
							viewBox='0 0 20 21'
							fill='none'>
							<path
								d='M3.79595 13.8876C2.17291 13.8876 0.857178 12.7807 0.857178 11.1576C0.857178 9.53459 2.17291 8.21886 3.79595 8.21886C3.90632 8.21886 4.01527 8.22495 4.12248 8.2368V8.21886H4.1629C4.13623 8.00492 4.12248 7.78696 4.12248 7.5658C4.12248 4.68039 6.46157 2.34131 9.34697 2.34131C11.3009 2.34131 13.0043 3.41392 13.9005 5.00245C14.1195 4.97024 14.3435 4.95355 14.5715 4.95355C17.0962 4.95355 19.1429 7.00025 19.1429 9.52498C19.1429 11.6033 17.756 13.1876 15.8572 13.7099M9.85575 17.8413V10.3413M9.85575 10.3413L6.85718 13.4344M9.85575 10.3413L12.8572 13.4344'
								stroke='black'
								strokeWidth='1.5'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</div>
				</div>
			</Dragger>
		</>
	);
}
