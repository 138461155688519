import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Progress, Steps } from 'antd';
import { handleNewChamaData } from '../../features/obj/objSlice';
import GroupDetailsStep from './steps/GroupDetailsStep';
import ChamaRolesStep from './steps/ChamaRolesStep';
import MerryGoRoundStep from './steps/MerryGoRoundStep';
import LoanSettingStep from './steps/LoanSettingStep';
import AddMemberStep from './steps/AddMemberStep';
import SettleMentAccountStep from './steps/SettlementAccountStep';
import { useNavigate } from 'react-router-dom';
import { applicationUrl, customToast } from '../../utils';
import { save } from '../../features/save/saveSlice';

export default function NewChama() {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const { newChamaData, chamaFeaturesArray } = useSelector(
		(state) => state.obj
	);
	const { chamaFeatures } = useSelector((state) => state.option);

	const formRef = useRef(null);

	const [current, setcurrent] = useState(0);
	const [subCurrent, setsubCurrent] = useState(0);
	const [features, setfeatures] = useState({
		hasMerryGoRound: false,
		hasLoans: false,
	});

	function handleSubCurrent(val) {
		setsubCurrent(val);
	}

	async function onFinish() {
		let data = {
			custId: newChamaData?.custId,
			custStatus: 'ACTIVE',
			url: applicationUrl().url,
			saveUrl: '/api/v1/customers',
		};

		const res = await dispatch(save(data));

		if (res?.payload?.success) {
			await customToast({
				content: 'Chama group created successful',
				bdColor: 'success',
			});
			await navigate('/home');
		} else {
			customToast({
				content: res?.payload?.messages?.message ?? 'An error occured',
				bdColor: 'error',
			});
		}
	}

	function handleNext() {
		if (current === 1) {
			if (features?.hasMerryGoRound) {
				setcurrent(2);
				setsubCurrent(3);
				return;
			}
			if (features.hasLoans) {
				setcurrent(3);
				setsubCurrent(3);
				return;
			}
			setcurrent(4);
			setsubCurrent(3);
			return;
		}
		if (current === 2) {
			if (features.hasLoans) {
				setcurrent(3);
				setsubCurrent(3);
				return;
			}
			setcurrent(4);
			setsubCurrent(3);
			return;
		}
		setcurrent(current + 1);
		setsubCurrent(3);
	}

	function handleBack() {
		if (current === 4) {
			if (features?.hasLoans) {
				setcurrent(3);
				return;
			} else if (features?.hasMerryGoRound && !features?.hasLoans) {
				setcurrent(2);
				return;
			} else if (!features?.hasMerryGoRound && !features?.hasLoans) {
				setcurrent(1);
				return;
			}
		}

		if (current === 3) {
			if (features?.hasMerryGoRound) {
				setcurrent(2);
				return;
			} else {
				setcurrent(1);
				return;
			}
		}

		setcurrent(current - 1);
	}

	const steps = [
		{
			key: 0,
			title: 'Group Details',
			content: (
				<GroupDetailsStep
					handleSubCurrent={handleSubCurrent}
					handleNext={handleNext}
					form={form}
				/>
			),
		},
		{
			key: 1,
			title: 'Roles',
			content: (
				<ChamaRolesStep
					handleNext={handleNext}
					handleBack={handleBack}
					form={form}
				/>
			),
		},
		features?.hasMerryGoRound
			? {
					key: 2,
					title: 'Merry-go-round',
					content: (
						<MerryGoRoundStep
							handleNext={handleNext}
							handleBack={handleBack}
							form={form}
						/>
					),
			  }
			: null,

		features.hasLoans
			? {
					key: 3,
					title: 'Loans',
					content: (
						<LoanSettingStep
							handleNext={handleNext}
							handleBack={handleBack}
							form={form}
						/>
					),
			  }
			: null,
		{
			key: 4,
			title: 'Add members',
			content: (
				<AddMemberStep
					handleNext={handleNext}
					handleBack={handleBack}
					form={form}
				/>
			),
		},
		{
			key: 5,
			title: 'Settlement account',
			content: <SettleMentAccountStep handleBack={handleBack} form={form} />,
		},
	];

	const items = steps.map((item) => ({
		key: item?.key,
		title: item?.title,
	}));

	const filteredItems = items?.filter(
		(item) => item.key !== undefined && item.title !== undefined
	);

	useEffect(() => {
		form.setFieldsValue({
			...newChamaData,
		});
	}, [newChamaData, form]);

	useEffect(() => {
		chamaFeaturesArray
			?.map((item) => {
				const matchingFeature = chamaFeatures?.find(
					(feature) => feature?.csfId === item
				);

				if (String(matchingFeature?.csfName).toUpperCase().includes('MER')) {
					setfeatures((prev) => ({
						...prev,
						hasMerryGoRound: true,
					}));
				}

				if (String(matchingFeature?.csfName).toUpperCase().includes('LOA')) {
					setfeatures((prev) => ({
						...prev,
						hasLoans: true,
					}));
				}

				return null;
			})
			.filter(Boolean);
	}, [chamaFeaturesArray, chamaFeatures]);

	return (
		<>
			<Progress
				type='line'
				className='profile-progress'
				showInfo={false}
				percent={
					((current + 1) /
						(features.hasLoans && !features.hasMerryGoRound
							? 5
							: features?.hasMerryGoRound && !features.hasLoans
							? 5
							: features?.hasMerryGoRound && features.hasLoans
							? 6
							: 4)) *
					100
				}
			/>
			<div className='flex w-full justify-center pt-[2.94rem] full_page'>
				<div
					style={{
						width: current === 4 ? '76.25rem' : '50rem',
					}}
					className='flex flex-col h-auto'>
					<span className='heading_1'>Create a chama</span>
					<span className='paragraph_1 mt-[1.25rem]'>
						Open a chama to manage group savings & investments
					</span>

					<div className='w-full h-full mt-[2.44rem]'>
						<Form
							onValuesChange={(data) => {
								dispatch(handleNewChamaData(data));
							}}
							layout='vertical'
							ref={formRef}
							name='registration'
							onFinish={onFinish}
							scrollToFirstError
							form={form}
							initialValues={{
								...newChamaData,
							}}
							className=''>
							<Steps
								className='register_step'
								current={current}
								labelPlacement='vertical'
								items={filteredItems}
							/>

							<div className='mt-[2.12rem] rounded-[0.5rem] border border-[#E2E9EE] bg-white'>
								<div className='flex justify-center items-center flex-col text-center gap-[1rem] pt-[2.5rem]'>
									{current === 1 ? (
										<>
											<span className='heading_2'>Roles</span>
											<span className='heading_5'>
												Choose a role and review the permissions
											</span>
										</>
									) : current === 2 ? (
										<>
											<span className='heading_2'>Merry-go-round</span>
											<span className='heading_5'>
												You have enabled the merry-go-round feature on your
												chama. <br /> Set your preferences below.
											</span>
										</>
									) : current === 3 ? (
										<>
											<span className='heading_2'>Loan settings</span>
											<span className='heading_5'>
												You have enabled the loans feature for your chama. Set
												your loan disbursement preferences below.
											</span>
										</>
									) : current === 4 ? (
										<>
											<span className='heading_2'>Members</span>
										</>
									) : current === 5 ? (
										<>
											<span className='heading_2'>Settlement account</span>
										</>
									) : (
										<>
											<span className='heading_2'>Group details</span>
											<span className='heading_5'>
												Please fill in the following details.
											</span>
										</>
									)}
								</div>

								<div className='bg-[#F1F1F1] h-[0.0625rem] w-full my-[2.94rem]'></div>

								{subCurrent === 0 ? (
									<div className='flex justify-center items-center'>
										<span className='label_2'>Part I</span>
									</div>
								) : subCurrent === 1 ? (
									<div className='flex justify-center items-center'>
										<span className='label_2'>Part II</span>
									</div>
								) : null}

								<div className=' p-[2.31rem] w-full'>
									{steps[current].content}
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
