import { Table } from 'antd';
import { applicationUrl, formatMoney } from '../../../utils';
import userImg from '../../../assets/img/chamaImg.png';
import moment from 'moment';
import useFetch from '../../../custom_hooks/useFetch';
import TableLoading from '../../../components/TableLoading';

export default function AllTransactionStatementTable() {
	const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/contr/fetchAll`);

	if (isLoading) return <TableLoading />

	const columns = [
		{
			title: 'Date',
			dataIndex: 'contDate',
			render: (item) => <span>{moment(item).format('Do MMMM YYYY')}</span>,
		},
		{
			title: 'Activity',
			dataIndex: 'trxSource',
			render: (item) => (
				<>
					{item === 'CONTRIBUTION' ? (
						<>
							<div className='flex w-fit h-[1.5rem] items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem] bg-[#E7F4E7]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='5'
									height='4'
									viewBox='0 0 5 4'
									fill='none'>
									<circle cx='2.66602' cy='2' r='2' fill='#212121' />
								</svg>
								<span className=''>{item}</span>
							</div>
						</>
					) : (
						<>
							<div className='flex items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem] bg-[#EEFCFF]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='5'
									height='4'
									viewBox='0 0 5 4'
									fill='none'>
									<circle cx='2.66602' cy='2' r='2' fill='#212121' />
								</svg>
								<span className=''>{item}</span>
							</div>
						</>
					)}
				</>
			),
		},
		{
			title: 'Member',
			dataIndex: 'trxMemberName',
			render: (item) => (
				<>
					<div className='w-full flex items-center gap-[.5rem]'>
						<img
							src={userImg}
							className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
							alt='avatar'
						/>
						<span>Member Name</span>
					</div>
				</>
			),
		},
		{
			title: 'Ref no.',
			dataIndex: 'contReferenceNumber',
		},
		{
			title: 'Debit (KES)',
			dataIndex: 'contAmount',
			render: (item) => <span>{formatMoney(item, 'KES')}</span>,
		},
		{
			title: 'Credit (KES)',
			dataIndex: 'contAmount',
			render: (item) => <span>{formatMoney(item, 'KES')}</span>,
		},
		{
			title: 'Running balance (KES)',
			dataIndex: 'contAmount',
			render: (item) => <span>{formatMoney(item, 'KES')}</span>,
		},
	];

	return (
		<>
			<Table
				rowSelection={true}
				rowKey='contId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				columns={columns}
				dataSource={data}
				scroll={{
					x: 1200,
				}}
			/>
		</>
	);
}
