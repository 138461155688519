import userImg from '../../../assets/img/chamaImg.png';

export default function MemberInfoCard() {
	return (
		<>
			<div className='flex items-start gap-[1.25rem] w-full h-[16.75rem] bg-[#F2F4F7] px-[1.5rem] py-[2.75rem]'>
				<div className='w-[9.125rem] h-[9.125rem]'>
					<img
						src={userImg}
						alt='avatar'
						className='w-full h-full rounded-full object-cover'
					/>
				</div>
				<div className='flex flex-col'>
					<span className='heading_1'>Ben Kinyanjui</span>
					<span className='label_3 !text-[#64748B] my-[.25rem]'>
						(Treasurer)
					</span>
					<span className='label_3 !text-[#64748B]'>
						Member since 10th Sept 2024
					</span>

					<div className='flex items-center mt-[1.25rem] w-full'>
						<span className='member_info_text w-[6rem]'>ID No</span>
						<span className='member_info_text !font-normal'>12345678</span>
					</div>

                    <div className='flex items-center my-[.5rem] w-full'>
						<span className='member_info_text w-[6rem]'>Phone No</span>
						<span className='member_info_text !font-normal'>0712345678</span>
					</div>

                    <div className='flex items-center w-full'>
						<span className='member_info_text w-[6rem]'>Email</span>
						<span className='member_info_text !font-normal'>b.kiny@gmail.com</span>
					</div>
				</div>
			</div>
		</>
	);
}
