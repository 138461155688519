import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	activeChama: {},
	chamaActive: false,
	newChamaData: {},
	chamaFeaturesArray: [],
	initialmemberInvite: {},
	activeTab: {
		memberBalance: '1',
		statements: '1'
	},
	activeMember: {}
};

export const objSlice = createSlice({
	name: 'obj',
	initialState,
	reducers: {
		setActiveChama: (state, action) => {
			state.activeChama = action.payload;
			state.chamaActive = true;
		},
		handleNewChamaData: (state, action) => {
			state.newChamaData = { ...state.newChamaData, ...action.payload };
		},
		handleChamaFeaturesArray: (state, action) => {
			state.chamaFeaturesArray = action.payload;
		},
		handleActiveTab: (state, action) => {
			console.log(action.payload);
			
			state.activeTab = { ...state.activeTab, ...action.payload };
		},
		handleInitialmemberInvite: (state, action) => {
			state.initialmemberInvite = action.payload;
		},
		setActiveMember: (state, action) => {
			state.activeMember = action.payload;
		},
		clearObj: (state) => {
			state.activeChama = {};
			state.chamaActive = false;
			state.chamaFeaturesArray = [];
			state.newChamaData = {};
			state.activeMember = {}
		},
	},
});

export default objSlice.reducer;
export const {
	setActiveChama,
	clearObj,
	handleNewChamaData,
	handleChamaFeaturesArray,
	handleActiveTab,
	handleInitialmemberInvite,
	setActiveMember
} = objSlice.actions;
