import { Table } from "antd";
import { formatMoney } from "../../../utils";
import userImg from '../../../assets/img/chamaImg.png'

export default function GroupContributionTable() {
    // const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/contr/fetchAll`);

	// if (isLoading) return <TableLoading />
    
    const columns = [
        {
            title: 'Member',
            dataIndex: 'member',
            render: (item) => (
                <>
                    <div className='w-full flex items-center gap-[.5rem]'>
                        <img
                            src={userImg}
                            className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
                            alt='avatar'
                        />
                        <span>Member Name</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Balance (KES)',
            dataIndex: 'trxRef',
        },
        {
            title: 'Jan',
            dataIndex: 'debit',
            render: (item) => <span>{formatMoney(0, 'KES')}</span>,
        },
        {
            title: 'Feb',
            dataIndex: 'trxType',
            render: (item) => <span>{formatMoney(0, 'KES')}</span>,
        },
        {
            title: 'Mar',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Apr',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'May',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Jun',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Jul',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Aug',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Sep',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Oct',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Nov',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
        {
            title: 'Dec',
            dataIndex: 'trxAmount',
            render: (item) => <span>{formatMoney(item, 'KES')}</span>,
        },
    ];

	return (
		<>
			<Table
				rowSelection={true}
				rowKey='trxId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				columns={columns}
				dataSource={[]}
				scroll={{
					x: 1200,
				}}
			/>
		</>
	);
}
