import { Tabs } from "antd";
import MemberInfoCard from "./cards/MemberInfoCard";
import MemberOverview from "./tabs/MemberOverviewTab";
import MemberStatementTab from "./tabs/MemberStatementTab";
import MemberLoansTab from "./tabs/MemberLoansTab";

export default function MemberDetails(){

    const items = [
		{
			key: '1',
			label: 'Overview',
			children: <MemberOverview />,
		},
        {
			key: '2',
			label: 'Statements',
			children: <MemberStatementTab />,
		},
        {
			key: '3',
			label: 'Loans',
			children: <MemberLoansTab />,
		},
        {
			key: '4',
			label: 'Permissions',
			children: "Permissions",
		},
        {
			key: '5',
			label: 'Contact info',
			children: "Contact info",
		},
	];

    return (
        <>
            <MemberInfoCard />

            <div className='flex flex-col w-full pt-[2.5rem] bg-white'>
					<Tabs
						className='underlined_tab'
						items={items}
					/>
				</div>
        </>
    )
}