import { Form, Input, InputNumber, Select, Spin, Tooltip } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, customToast } from '../../../../utils';
import { save, update } from '../../../../features/save/saveSlice';
import { handleNewChamaData } from '../../../../features/obj/objSlice';
import { fetchChamaFeatures } from '../../../../features/options/optionSLice';
import info from '../../../../assets/svg/Info.svg';
import info32 from '../../../../assets/svg/Info32.svg';
import { collectionIntervals } from '../../../../data';

export default function GroupDetailsSubStepTwo({
	handleActiveStep,
	form,
	handleNext,
}) {
	const dispatch = useDispatch();

	const { newChamaData, chamaFeaturesArray } = useSelector(
		(state) => state.obj
	);
	const { saving } = useSelector((state) => state.save);

	async function handleFetch() {
		await dispatch(fetchChamaFeatures());
	}

	async function handleSubmitStep() {
		try {
			await form.validateFields([
				'custSeat',
				'custMinContribution',
				'custContributionFrequency',
			]);
			let data = form.getFieldsValue();

			data.custId = newChamaData?.custId;
			data.custType = 'GROUP';
			data.custKra = 'KRAPIN' + Math.floor(Math.random() * 10) + "UDN" + Math.floor(Math.random() * 10) ;
			data.custStatus = "DRAFT"
			data.url = applicationUrl().url;
			data.saveUrl = '/api/v1/customers';

			const custObj = {
				...data,
			};

			Object.keys(newChamaData).forEach((key) => {
				if (key.startsWith('cust')) {
					custObj[key] = newChamaData[key];
				}
			});

			const res = await dispatch(save(custObj));

			if (res?.payload?.success) {
				await dispatch(handleNewChamaData(res?.payload?.data?.result));
				const uniqueArray = [...new Set(chamaFeaturesArray)];

				let updateObj = {
					url: applicationUrl().url,
					saveUrl: `/api/v1/customers/${res?.payload?.data?.result?.custId}/features`,
					data: uniqueArray,
				};
				await dispatch(update(updateObj));
				handleActiveStep(1);
				handleNext();
			} else {
				customToast({
					content: res?.payload?.messages?.message ?? 'An error occured',
					bdColor: 'error',
				});
			}
		} catch {}
	}

	useEffect(() => {
		handleFetch();
	}, []);

	return (
		<>
			<div className='flex flex-col w-full'>
				<Form.Item
					label={
						<>
							<div className='w-full flex items-center gap-[.5rem]'>
								<span>Maximum no. of seats per member</span>
								<Tooltip
									arrow={false}
									// open={true}
									placement='rightTop'
									title={
										<>
											<div className='w-full flex flex-col p-[1rem] gap-[.5rem]'>
												<div className='flex items-start gap-[.5rem]'>
													<img src={info32} alt='info' />
													<span className='toolTip_header'>No. of seats</span>
												</div>

												<span className='toolTip_text'>
													A “seat” represents a members shares within a group.
													Each seat costs the same as the group’s minimum
													contribution.
												</span>
											</div>
										</>
									}>
									<img src={info} alt='info' />
								</Tooltip>
							</div>
						</>
					}
					name='custSeat'
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
					]}>
					<Select
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='21'
								height='21'
								viewBox='0 0 21 21'
								fill='none'>
								<path
									d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
									fill='#212121'
								/>
							</svg>
						}
						options={[1, 2, 3, 4, 5].map((item, index) => {
							return {
								label: (
									<div key={index}>
										<span>{item}</span>
									</div>
								),
								value: item,
							};
						})}
					/>
				</Form.Item>

				<Form.Item
					label='Minimum contribution'
					name='custMinContribution'
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
					]}>
					<div className='flex items-center gap-[.5rem]'>
						<div
							type='button'
							disabled
							className='input !w-[70px] hover:!border'>
							KES
						</div>
						<InputNumber
							min={1}
							precision={2}
							placeholder={'0.00'}
							className='input'
						/>
					</div>
				</Form.Item>

				<Form.Item
					label='Contribution frequency'
					name='custContributionFrequency'
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
					]}>
					<Select
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='21'
								height='21'
								viewBox='0 0 21 21'
								fill='none'>
								<path
									d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
									fill='#212121'
								/>
							</svg>
						}
						options={collectionIntervals}
					/>
				</Form.Item>

				<Form.Item
					label='Contribution day'
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
					]}>
					<Input disabled className='input' />
				</Form.Item>

				<div className='py-[2.44rem] w-full flex justify-center items-center'>
					<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
						<button
							disabled={saving}
							onClick={() => handleSubmitStep()}
							type='button'
							className='cstm-btn-2'>
							{saving ? <Spin /> : 'Next'}
						</button>

						<button
							onClick={() => handleActiveStep(0)}
							type='button'
							className='cstm-btn'>
							Previous
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
