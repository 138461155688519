import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	authLoading: false,
	user: {},
	isActive: false,
	refresh_token: '',
	chooseAccount: false,
	authData: {},
	newInviteObj: {},
};

export const login = createAsyncThunk('authSlice/login', async (data) => {
	const res = await axios
		.post(`${url}/auth/login`, data)
		.then((res) => res.data);
	return res;
});

export const register = createAsyncThunk('authSlice/register', async (data) => {
	const res = await axios
		.post(`${url}/api/v1/reg`, data)
		.then((res) => res.data);
	return res;
});

export const verifyRegOtp = createAsyncThunk(
	'authSlice/verifyRegOtp',
	async (data) => {
		const res = await axios
			.post(`${url}/api/v1/reg/verifyOTP`, data)
			.then((res) => res.data);
		return res;
	}
);

export const resendRegOtp = createAsyncThunk(
	'authSlice/resendRegOtp',
	async (data) => {
		const res = await axios
			.post(`${url}/api/v1/reg/sendOTP`, data)
			.then((res) => res.data);
		return res;
	}
);

export const verifyAuthDraftUser = createAsyncThunk(
	'authSlice/verifyAuthDraftUser',
	async (data) => {
		const res = await axios
			.post(`${url}/api/v2/users/verify`, data)
			.then((res) => res.data);
		return res;
	}
);

export const updateOnboarding = createAsyncThunk(
	'authSlice/updateOnboarding',
	async (data) => {
		const res = await axiosInstance
			.post(`${url}/api/v2/users/update-onBoarding`, data)
			.then((res) => res.data);
		return res;
	}
);

export const fetchNewUserInviteObj = createAsyncThunk(
	'authSlice/fetchNewUserInviteObj',
	async (id) => {
		const res = await axios
			.get(`${url}/api/v1/reg/me/${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchChamaToken = createAsyncThunk(
	'authSlice/fetchChamaToken',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/api/v1/customers/token/${id}`)
			.then((res) => res.data);
		return res;
	}
);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.user = {};
			state.isActive = false;
			state.refresh_token = '';
			state.chooseAccount = false;
			state.authData = {};
			state.newInviteObj = {};
		},
		handleLoginSession: (state, action) => {
			state.isActive = action.payload;
		},
		handleUserRefreshToken: (state, action) => {
			state.refresh_token = action.payload;
		},
		handleChooseAccount: (state, action) => {
			state.chooseAccount = action.payload;
		},
		handleUser: (state, action) => {
			state.user = action.payload;
		},
		handleAuthData: (state, action) => {
			state.authData = { ...state.authData, ...action.payload };
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchChamaToken.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchChamaToken.fulfilled, (state, action) => {
				state.authLoading = false;
				localStorage.setItem('token', action.payload.token)
			})
			.addCase(fetchChamaToken.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchNewUserInviteObj.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchNewUserInviteObj.fulfilled, (state, action) => {
				state.authLoading = false;
				state.newInviteObj = action.payload;
			})
			.addCase(fetchNewUserInviteObj.rejected, (state) => {
				state.authLoading = false;
				state.newInviteObj = {
					mbiStatus: 'REJECT',
				};
			})

			.addCase(verifyAuthDraftUser.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(verifyAuthDraftUser.fulfilled, (state, action) => {
				state.authLoading = false;
			})
			.addCase(verifyAuthDraftUser.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(resendRegOtp.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(resendRegOtp.fulfilled, (state, action) => {
				state.authLoading = false;
			})
			.addCase(resendRegOtp.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(verifyRegOtp.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(verifyRegOtp.fulfilled, (state, action) => {
				state.authLoading = false;
			})
			.addCase(verifyRegOtp.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(register.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(register.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(register.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(login.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.authLoading = false;
			})
			.addCase(login.rejected, (state) => {
				state.authLoading = false;
			});
	},
});

export default authSlice.reducer;
export const {
	logout,
	setAuth,
	handleUserRefreshToken,
	handleChooseAccount,
	handleLoginSession,
	handleUser,
	handleAuthData,
} = authSlice.actions;
