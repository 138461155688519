import { Form, Input } from 'antd';
import { useState } from 'react';
import FileUploadDragger from '../../../components/FileUploadDragger';
import { useDispatch, useSelector } from 'react-redux';
import { handleAuthData, register } from '../../../features/auth/authSlice';
import { customToast } from '../../../utils';

export default function VerifyIdStep({ handleNext, handleBack, form }) {
	const dispatch = useDispatch();

	const { authLoading, authData } = useSelector((state) => state.auth);

	const [idFile, setidFile] = useState({
		front: '',
		back: '',
	});

	async function handleSubmit() {
		try {
			await form.validateFields([
				'usrFirstName',
				'usrLastName',
				'usrMobileNumber',
			]);
			let data = form.getFieldsValue();
			data.usrNationalIdFrontFile = idFile.front;
			data.usrNationalIdBackFile = idFile.back;
			data.usrId = authData?.usrId;

			const res = await dispatch(register(data));

			if (res?.payload?.success) {
				await dispatch(handleAuthData(res?.payload?.data?.result));
				await handleNext()
			} else {
				customToast({
					content: res?.payload?.messages?.message,
					bdColor: 'error',
				});
			}
		} catch {}
	}

	const handleSuccess = (response) => {
		console.log(response);
		
		setidFile((prev) => ({
			...prev,
			[response.fileName]: response?.data?.result,
		}));
	};

	return (
		<>
			<div className='flex flex-col w-full h-full'>
				<Form.Item
					label='National ID'
					name='usrNationalId'
					rules={[
						{
							required: true,
							message: 'National is required',
						},
					]}>
					<Input className='input' />
				</Form.Item>

				<Form.Item label='Upload front copy of ID'>
					<FileUploadDragger
						fileName={idFile.front?.split('_').pop()}
						name='front'
						onSuccess={handleSuccess}
					/>
				</Form.Item>

				<Form.Item label='Upload back copy of ID'>
					<FileUploadDragger
						fileName={idFile.back?.split('_').pop()}
						name='back'
						onSuccess={handleSuccess}
					/>
				</Form.Item>

				<div className='py-[2.44rem] w-full flex justify-center items-center'>
					<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
						<button
							disabled={authLoading}
							onClick={handleSubmit}
							type='button'
							className='cstm-btn-2'>
							Next
						</button>

						<button
							disabled={authLoading}
							onClick={handleBack}
							type='button'
							className='cstm-btn'>
							Previous
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
