import { Table } from 'antd';
import userImg from '../../../assets/img/chamaImg.png';
import moment from 'moment';
import { formatMoney } from '../../../utils';

const columns = [
	{
		title: 'Date',
		dataIndex: 'date',
		render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        sorter: (a, b) => a - b,
	},
	{
		title: 'Member',
		dataIndex: 'member',
		render: (item) => (
			<>
				<div className='w-full flex items-center gap-[.5rem]'>
					<img
						src={userImg}
						className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
						alt='avatar'
					/>
					<span>Member Name</span>
				</div>
			</>
		),
	},
	{
		title: 'Total balance (KES)',
		dataIndex: 'trxRef',
		render: (item) => <span>{formatMoney(0, 'KES')}</span>,
	},
];

export default function MembersTotalBalanceTable() {
	return (
		<>
			<Table
				rowSelection={true}
				rowKey='trxId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				columns={columns}
				dataSource={[]}
			/>
		</>
	);
}
