import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	optLoading: false,
	chamaFeatures: [],
};

export const fetchChamaFeatures = createAsyncThunk(
	'optionSlice/fetchChamaFeatures',
	async () => {
		const res = await axiosInstance
			.get(`${url}/api/v1/customers/features`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const optionSlice = createSlice({
	name: 'option',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(fetchChamaFeatures.pending, (state) => {
				state.optLoading = true;
			})
			.addCase(fetchChamaFeatures.fulfilled, (state, action) => {
				state.optLoading = false;
				state.chamaFeatures = action.payload;
			})
			.addCase(fetchChamaFeatures.rejected, (state) => {
				state.optLoading = false;
			});
	},
});

export default optionSlice.reducer;
export const {} = optionSlice.actions;
