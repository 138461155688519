import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../../layout/BreadCrumb';
import InAppNotificationBar from '../../components/InAppNotificationBar';
import Search from '../../components/Search';
import StatementFilter from '../statements/StatementFilter';
import MakeContributionModal from './modals/MakeContributionModal';
import { Link } from 'react-router-dom';
import ActivityAmountSummaryCard from './cards/ActivityAmountSummaryCard';
import ActivityArrearsTable from '../arrears/tables/ActivityArrearsTable';
import { fetchMemberSchedules } from '../../features/money/moneySlice';
import { capitalize } from '../../utils';



export default function ActivityArrears() {
	const dispatch = useDispatch();

	const { memberSchedules } = useSelector((state) => state.money);
	const { activeChama } = useSelector((state) => state.obj);

	const [openPay, setopenPay] = useState(false);

	let totalAmount = memberSchedules?.reduce((a, b) => a + (b?.schContAmount || 0), 0);

	function handleCancel() {
		setopenPay(false);
	}

	async function handleInit() {
		await dispatch(fetchMemberSchedules());
	}

	useEffect(() => {
	}, [memberSchedules]);

	useEffect(() => {
		handleInit();
	}, []);

	const breadList = [
		{
			title: (
				<div className='flex items-center gap-[.25rem]'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='14'
						height='14'
						viewBox='0 0 14 14'
						fill='none'>
						<g clipPath='url(#clip0_627_13942)'>
							<path
								d='M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z'
								fill='black'
								fillOpacity='0.45'
							/>
						</g>
						<defs>
							<clipPath id='clip0_627_13942'>
								<rect width='14' height='14' fill='white' />
							</clipPath>
						</defs>
					</svg>
					<span>Home</span>
				</div>
			),
			href: '/#/home',
		},
		{
			title: capitalize(activeChama?.custName),
		},
	];

	return (
		<>
			<div className='grid grid-cols-1 gap-[1.25rem]'>
				<div className='justify-between items-center white_card'>
					<BreadCrumb
						breadList={breadList}
						header={'Your arrears'}
						info={'Here’s an overview of your arrears within Biz Chama'}
					/>
				</div>

				<div className='white_card flex-col'>
					<div className='w-full flex justify-between items-center'>
						<span className='heading_4'>Summary</span>
						<div className='flex w-auto'>
							<button
								onClick={() => setopenPay(true)}
								type='button'
								className='cstm-btn-2 !gap-[.25rem]'>
								<svg
									className='flex flex-shrink-0'
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='25'
									viewBox='0 0 24 25'
									fill='none'>
									<path
										d='M11.65 18.45L15.35 11.25H12.5V6.5L8.675 13.75H11.65V18.45ZM12 22C10.6833 22 9.446 21.75 8.288 21.25C7.12933 20.75 6.125 20.075 5.275 19.225C4.425 18.375 3.75 17.3707 3.25 16.212C2.75 15.054 2.5 13.8167 2.5 12.5C2.5 11.1833 2.75 9.94567 3.25 8.787C3.75 7.629 4.425 6.625 5.275 5.775C6.125 4.925 7.12933 4.25 8.288 3.75C9.446 3.25 10.6833 3 12 3C13.3167 3 14.5543 3.25 15.713 3.75C16.871 4.25 17.875 4.925 18.725 5.775C19.575 6.625 20.25 7.629 20.75 8.787C21.25 9.94567 21.5 11.1833 21.5 12.5C21.5 13.8167 21.25 15.054 20.75 16.212C20.25 17.3707 19.575 18.375 18.725 19.225C17.875 20.075 16.871 20.75 15.713 21.25C14.5543 21.75 13.3167 22 12 22ZM12 20.5C14.2333 20.5 16.125 19.725 17.675 18.175C19.225 16.625 20 14.7333 20 12.5C20 10.2667 19.225 8.375 17.675 6.825C16.125 5.275 14.2333 4.5 12 4.5C9.76667 4.5 7.875 5.275 6.325 6.825C4.775 8.375 4 10.2667 4 12.5C4 14.7333 4.775 16.625 6.325 18.175C7.875 19.725 9.76667 20.5 12 20.5Z'
										fill='#FFCD47'
									/>
								</svg>
								<span className='flex flex-shrink-0'>Pay arrears</span>
							</button>
						</div>
					</div>

					<div className='w-full grid grid-cols-2  xl:grid-cols-4 gap-[.75rem] mt-[1.69rem]'>
						<ActivityAmountSummaryCard
							text={'Total arrears'}
							amount={totalAmount}
						/>
					</div>
				</div>

				<div className='white_card flex-col'>
					<span className='heading_4 mt-[3.16rem]'>Notifications</span>

					{memberSchedules?.length ? (
						<>
							<div className='w-full grid grid-cols-1 gap-[1rem] mt-[1.25rem]'>
								<InAppNotificationBar
									color={'#FFB07C'}
									header={'You have arrears'}
									message={
										'Your currently have accrued areas of KES ' + totalAmount
									}
									showPay={true}
									type='ARREARS'
									amount={totalAmount}
								/>
							</div>
						</>
					) : (
						<div className='w-full grid grid-cols-1 gap-[1rem] mt-[1.25rem]'>
							<InAppNotificationBar
								color={'#8EC68E'}
								header={'Up to date'}
								message={'Your currently have KES 0 areas'}
							/>
						</div>
					)}
				</div>

				<div className='white_card flex-col'>
					<div className='flex justify-between items-center'>
						<span className='heading_4'>List of arrears</span>
						<Link
							to='/activity/history'
							className='flex items-center gap-[1rem]'>
							<span className='view_more_text'>View more</span>
							<div
								style={{
									background: '#fff',
									boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
								}}
								className='w-[1.5rem] h-[1.5rem] flex justify-center items-center rounded-full'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'>
									<path
										d='M3.85336 13.3535C3.8069 13.4 3.75175 13.4368 3.69106 13.462C3.63036 13.4871 3.5653 13.5001 3.49961 13.5001C3.43391 13.5001 3.36886 13.4871 3.30816 13.462C3.24746 13.4368 3.19231 13.4 3.14586 13.3535C3.0994 13.3071 3.06255 13.2519 3.03741 13.1912C3.01227 13.1305 2.99933 13.0655 2.99933 12.9998C2.99933 12.9341 3.01227 12.869 3.03741 12.8083C3.06255 12.7476 3.0994 12.6925 3.14586 12.646L7.79273 7.99979L3.14586 3.35354C3.05204 3.25972 2.99933 3.13247 2.99933 2.99979C2.99933 2.86711 3.05204 2.73986 3.14586 2.64604C3.23968 2.55222 3.36693 2.49951 3.49961 2.49951C3.63229 2.49951 3.75954 2.55222 3.85336 2.64604L8.85336 7.64604C8.89985 7.69248 8.93672 7.74762 8.96189 7.80832C8.98705 7.86902 9 7.93408 9 7.99979C9 8.0655 8.98705 8.13056 8.96189 8.19126C8.93672 8.25196 8.89985 8.3071 8.85336 8.35354L3.85336 13.3535ZM8.14586 3.35354L12.7927 7.99979L8.14586 12.646C8.0994 12.6925 8.06255 12.7476 8.03741 12.8083C8.01227 12.869 7.99933 12.9341 7.99933 12.9998C7.99933 13.0655 8.01227 13.1305 8.03741 13.1912C8.06255 13.2519 8.0994 13.3071 8.14586 13.3535C8.19231 13.4 8.24746 13.4368 8.30816 13.462C8.36885 13.4871 8.43391 13.5001 8.49961 13.5001C8.5653 13.5001 8.63036 13.4871 8.69105 13.462C8.75175 13.4368 8.8069 13.4 8.85336 13.3535L13.8534 8.35354C13.8998 8.3071 13.9367 8.25196 13.9619 8.19126C13.987 8.13056 14 8.0655 14 7.99979C14 7.93408 13.987 7.86902 13.9619 7.80832C13.9367 7.74762 13.8998 7.69248 13.8534 7.64604L8.85336 2.64604C8.75954 2.55222 8.63229 2.49951 8.49961 2.49951C8.36692 2.49951 8.23968 2.55222 8.14586 2.64604C8.05204 2.73986 7.99933 2.86711 7.99933 2.99979C7.99933 3.13247 8.05204 3.25972 8.14586 3.35354Z'
										fill='#343330'
									/>
								</svg>
							</div>
						</Link>
					</div>

					<div className='w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem] pb-[5rem]'>
						<div className='flex items-center gap-[1rem] w-full'>
							<div className='w-[13.625rem] '>
								<Search text={'Search'} />
							</div>

							<div className='w-[12.5rem]'>
								<StatementFilter />
							</div>
						</div>

						<ActivityArrearsTable handleFetch={handleInit} data={memberSchedules} />
					</div>
				</div>
			</div>

			<MakeContributionModal open={openPay} handleCancel={handleCancel} amount={totalAmount} type="ARREARS" />
		</>
	);
}
