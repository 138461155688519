import { Table } from 'antd';
import { formatMoney } from '../../../utils';
import userImg from '../../../assets/img/chamaImg.png';
import moment from 'moment';

export default function WithdrawalStatementTable({ data }) {
	const columns = [
		{
			title: 'Date',
			dataIndex: 'createdAt',
			render: (item) => <span>{moment(item).format('Do MMMM YYYY')}</span>,
		},
		{
			title: 'Ref no.',
			dataIndex: 'trxRef',
		},
		{
			title: 'Member',
			dataIndex: 'member',
			render: (item) => (
				<>
					<div className='w-full flex items-center gap-[.5rem]'>
						<img
							src={userImg}
							className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
							alt='avatar'
						/>
						<span>Member Name</span>
					</div>
				</>
			),
		},
		{
			title: 'Activity',
			dataIndex: 'trxSource',
			render: (item) => (
				<>
					<div className='flex w-fit h-[1.5rem] items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem] bg-[#E7F4E7]'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='5'
							height='4'
							viewBox='0 0 5 4'
							fill='none'>
							<circle cx='2.66602' cy='2' r='2' fill='#212121' />
						</svg>
						<span className=''>Payout</span>
					</div>
				</>
			),
		},

		{
			title: 'Outgoing (KES)',
			dataIndex: 'outGoing',
			render: (item) => <span>{formatMoney(0, 'KES')}</span>,
		},
	];

	return (
		<>
			<Table
				rowSelection={true}
				rowKey='trxId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				columns={columns}
				dataSource={data}
				scroll={{
					x: 1200,
				}}
			/>
		</>
	);
}
