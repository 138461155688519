import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SideBarClosed from './SideBarClosed';
import SideBarOpen from './SideBarOpen';

export default function SideBar() {
	const { collapsed } = useSelector((state) => state.setup);	

	useEffect(() => {}, [collapsed]);

	if (collapsed || Number(window.innerWidth) < 1024) return <SideBarClosed />;

	return <SideBarOpen />;
}
