import PageActions from '../../../components/PageActions';
import Search from '../../../components/Search';
import AvailableBalanceCard from '../../group/cards/AvailbaleBalanceCard';
import MemberRecentTransactionTable from '../tables/MemberRecentTransactionTable';

export default function MemberOverview() {
	const balanceObj = [
		{
			header: 'Total Savings',
			amount: 'KES 40,000',
			background: 'rgba(147, 205, 147, 0.22)',
		},
		{
			header: 'No. of Seats',
			amount: '2',
			hasFooter: true,
			footer: 'KES 1000 each',
			background: '#fff',
		},
		{
			header: 'Active loans',
			amount: '2',
			background: '#fff',
		},
		{
			header: 'Arrears',
			amount: 'KES 0',
			background: '#fff',
		},
		{
			header: 'Penalties',
			amount: 'KES 500',
			background: 'rgba(255, 228, 230, 0.69)',
		},
	];

	const contributionArray = [
		{
			header: 'Last contribution',
			amount: 'KES 2,000',
			hasFooter: true,
			footer: '11th July 2024',
			background: '#fff',
		},
		{
			header: 'Expected monthly contribution',
			amount: 'KES 2,000',
			background: '#fff',
		},
	];

	return (
		<>
			<div className='mt-[2.5rem] w-full flex flex-col !pb-[5rem] p-[1.25rem]'>
				<div className='flex items-center justify-between'>
					<span className='heading_4'>Overview of Ben’s activity</span>
					<PageActions />
				</div>

				<div className='mt-[1.25rem] flex w-full items-center overflow-x-auto overflow-y-hidden gap-[.75rem]'>
					{balanceObj.map((item, index) => {
						return (
							<div className='flex flex-grow flex-shrink-0 w-auto' key={index}>
								<AvailableBalanceCard
									header={item.header}
									footer={item.footer}
									hasFooter={item.hasFooter}
									amount={item.amount}
									background={item.background}
								/>
							</div>
						);
					})}
				</div>

				<div className='mt-[1.25rem] flex w-full items-center overflow-x-auto overflow-y-hidden gap-[.75rem]'>
					{contributionArray.map((item, index) => {
						return (
							<div className='flex flex-shrink-0 w-[16.25rem]' key={index}>
								<AvailableBalanceCard
									header={item.header}
									footer={item.footer}
									hasFooter={item.hasFooter}
									amount={item.amount}
									background={item.background}
								/>
							</div>
						);
					})}
				</div>

				<span className='heading_4 mt-[2.5rem]'>Ben’s recent transactions</span>
				<div className='flex items-center gap-[1rem] w-full mt-[1.25rem]'>
					<div className='w-[24.1875rem] '>
						<Search text={'Search ref no., activity or date'} />
					</div>
				</div>

				<MemberRecentTransactionTable />
			</div>
		</>
	);
}
