import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Spin } from 'antd';
import AuthHeader from '../../layout/AuthHeader';
import DeclineInviteModal from './modals/DeclineInviteModal';
import { save } from '../../features/save/saveSlice';
import { applicationUrl, customToast } from '../../utils';
import NewUserSuccessModal from './modals/NewUserSuccessModal';
import { fetchNewUserInviteObj, logout } from '../../features/auth/authSlice';

const icon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='120'
		height='115'
		viewBox='0 0 120 115'
		fill='none'>
		<path
			d='M0.0517578 57.2C0.0517578 72.3704 6.03603 86.9194 16.6881 97.6465C27.3402 108.374 41.7875 114.4 56.8518 114.4C71.916 114.4 86.3634 108.374 97.0154 97.6465C107.667 86.9194 113.652 72.3704 113.652 57.2C113.652 42.0296 107.667 27.4806 97.0154 16.7535C86.3634 6.02641 71.916 0 56.8518 0C41.7875 0 27.3402 6.02641 16.6881 16.7535C6.03603 27.4806 0.0517578 42.0296 0.0517578 57.2Z'
			fill='#FFF7EB'
		/>
		<path
			d='M83.6399 93.3878H30.6739C29.7256 93.3878 28.8162 93.0084 28.1456 92.3331C27.4751 91.6579 27.0984 90.742 27.0984 89.787V20.7352C27.0984 19.7396 27.4911 18.7849 28.1901 18.0809C28.8892 17.3769 29.8373 16.9814 30.8259 16.9814H83.4795C84.4681 16.9814 85.4162 17.3769 86.1152 18.0809C86.8143 18.7849 87.207 19.7396 87.207 20.7352V89.787C87.2074 90.7406 86.8319 91.6554 86.1632 92.3305C85.4944 93.0056 84.5869 93.3859 83.6399 93.3878Z'
			fill='#FFC263'
		/>
		<path
			d='M83.6399 93.3878H30.6739C29.7256 93.3878 28.8162 93.0084 28.1456 92.3331C27.4751 91.6579 27.0984 90.742 27.0984 89.787V20.7352C27.0984 19.7396 27.4911 18.7849 28.1901 18.0809C28.8892 17.3769 29.8373 16.9814 30.8259 16.9814H83.4795C84.4681 16.9814 85.4162 17.3769 86.1152 18.0809C86.8143 18.7849 87.207 19.7396 87.207 20.7352V89.787C87.2074 90.7406 86.8319 91.6554 86.1632 92.3305C85.4944 93.0056 84.5869 93.3859 83.6399 93.3878Z'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M33.0127 22.9326H81.2955V87.4328H33.0127V22.9326Z' fill='white' />
		<path
			d='M81.2955 45.0293L67.2745 60.3303C66.3902 61.2978 65.9235 62.5792 65.9767 63.893C66.03 65.2068 66.5989 66.4456 67.5585 67.3373C68.1606 67.8962 68.8933 68.2927 69.6885 68.4899L65.1303 73.0816L65.8275 73.7837L59.6704 79.9828C59.3947 80.2592 59.2063 80.6114 59.1287 80.9952C59.0512 81.3791 59.088 81.7775 59.2346 82.1403C59.3811 82.5032 59.6309 82.8144 59.9525 83.0347C60.274 83.2551 60.6531 83.3749 61.0421 83.379C61.1852 83.3787 61.3279 83.3639 61.4681 83.3347C66.8641 82.1793 69.6089 80.5105 69.6402 78.3683L70.0122 77.9922L71.4067 79.3965L74.836 75.933C75.079 76.1289 75.3395 76.3017 75.6141 76.4493C76.5209 76.9354 77.5559 77.1239 78.5743 76.9885C79.5927 76.8531 80.5436 76.4006 81.2941 75.6942L81.2955 45.0293Z'
			fill='#FFC263'
		/>
		<path
			d='M33.0127 22.9326H81.2955V87.4328H33.0127V22.9326Z'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M39.8955 38.8105H70.0038'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M39.8955 47.7402H70.0038'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M39.8955 57.665H60.8803'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M43.6684 14.0029H70.6399C71.113 14.0044 71.5663 14.1948 71.9004 14.5323C72.2344 14.8697 72.422 15.3268 72.422 15.8033V25.0983C72.422 25.5762 72.2335 26.0345 71.8979 26.3724C71.5624 26.7103 71.1073 26.9001 70.6328 26.9001H43.6698C43.1953 26.9001 42.7402 26.7103 42.4047 26.3724C42.0691 26.0345 41.8806 25.5762 41.8806 25.0983V15.8033C41.8806 15.3258 42.069 14.8679 42.4042 14.5302C42.7395 14.1926 43.1942 14.0029 43.6684 14.0029Z'
			fill='white'
		/>
		<path
			d='M43.6684 14.0029H70.6399C71.113 14.0044 71.5663 14.1948 71.9004 14.5323C72.2344 14.8697 72.422 15.3268 72.422 15.8033V25.0983C72.422 25.5762 72.2335 26.0345 71.8979 26.3724C71.5624 26.7103 71.1073 26.9001 70.6328 26.9001H43.6698C43.1953 26.9001 42.7402 26.7103 42.4047 26.3724C42.0691 26.0345 41.8806 25.5762 41.8806 25.0983V15.8033C41.8806 15.3258 42.069 14.8679 42.4042 14.5302C42.7395 14.1926 43.1942 14.0029 43.6684 14.0029Z'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M48.7776 22.9326H65.5293'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M52.7195 13.01C52.7195 14.326 53.2386 15.5881 54.1627 16.5187C55.0868 17.4493 56.3401 17.9721 57.6469 17.9721C58.9537 17.9721 60.207 17.4493 61.1311 16.5187C62.0551 15.5881 62.5743 14.326 62.5743 13.01C62.5743 11.6939 62.0551 10.4318 61.1311 9.50122C60.207 8.57064 58.9537 8.04785 57.6469 8.04785C56.3401 8.04785 55.0868 8.57064 54.1627 9.50122C53.2386 10.4318 52.7195 11.6939 52.7195 13.01Z'
			fill='white'
		/>
		<path
			d='M52.7195 13.01C52.7195 14.326 53.2386 15.5881 54.1627 16.5187C55.0868 17.4493 56.3401 17.9721 57.6469 17.9721C58.9537 17.9721 60.207 17.4493 61.1311 16.5187C62.0551 15.5881 62.5743 14.326 62.5743 13.01C62.5743 11.6939 62.0551 10.4318 61.1311 9.50122C60.207 8.57064 58.9537 8.04785 57.6469 8.04785C56.3401 8.04785 55.0868 8.57064 54.1627 9.50122C53.2386 10.4318 52.7195 11.6939 52.7195 13.01Z'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M77.8989 57.0472L95.159 63.5308L102.419 51.8048L83.6528 46.7012L77.8989 57.0472Z'
			fill='#D9EDFF'
		/>
		<path
			d='M105.416 76.832L118.784 63.37C118.836 63.3193 118.891 63.2716 118.948 63.227C114.474 59.3174 113.371 51.0348 104.765 46.5103C93.438 40.5529 79.4283 41.9744 67.1225 57.4498C66.8408 57.7866 66.6286 58.1766 66.4984 58.5971C66.3682 59.0175 66.3227 59.4598 66.3643 59.8982C66.406 60.3366 66.5341 60.7621 66.7412 61.15C66.9482 61.5379 67.2301 61.8803 67.5701 62.1572C67.9102 62.434 68.3017 62.6397 68.7218 62.7623C69.1418 62.8848 69.5819 62.9218 70.0162 62.8709C70.4506 62.8201 70.8706 62.6825 71.2515 62.4661C71.6324 62.2498 71.9666 61.9591 72.2345 61.6111C73.0506 60.5949 73.8704 59.6487 74.694 58.7726C74.9834 59.0838 75.3302 59.3354 75.715 59.5133C88.5901 69.3303 91.193 70.5772 94.3425 71.8113C99.2131 73.7175 101.363 74.6384 104.944 77.9031C104.966 77.5 105.134 77.1189 105.416 76.832ZM96.5946 55.8782C95.5098 57.2001 94.0003 58.0985 92.3273 58.4181C90.6543 58.7378 88.923 58.4585 87.4328 57.6286L79.8188 54.1222C84.2619 50.8475 88.7449 49.4804 93.2093 50.0667C97.0078 50.5586 97.8868 53.942 96.5946 55.8782Z'
			fill='#B06C49'
		/>
		<path
			d='M35.468 104.876C35.468 105.534 37.7195 106.166 41.727 106.631C45.7346 107.097 51.17 107.359 56.8376 107.359C62.5052 107.359 67.9406 107.097 71.9482 106.631C75.9557 106.166 78.2072 105.534 78.2072 104.876C78.2072 104.218 75.9557 103.586 71.9482 103.121C67.9406 102.655 62.5052 102.394 56.8376 102.394C51.17 102.394 45.7346 102.655 41.727 103.121C37.7195 103.586 35.468 104.218 35.468 104.876Z'
			fill='#FFE8C3'
		/>
		<path
			d='M42.9031 67.9766C42.3936 68.4893 42.0176 69.1206 41.8083 69.8147C41.599 70.5087 41.563 71.244 41.7033 71.9555C41.8437 72.6669 42.1562 73.3325 42.613 73.8932C43.0699 74.454 43.657 74.8926 44.3224 75.1703C44.9878 75.4479 45.711 75.556 46.4278 75.4849C47.1446 75.4138 47.8329 75.1658 48.4317 74.7628C49.0306 74.3599 49.5215 73.8143 49.861 73.1746C50.2005 72.5349 50.378 71.8207 50.3779 71.0954V71.9763C50.3686 72.4456 50.4523 72.9121 50.6241 73.3485C50.796 73.7849 51.0526 74.1823 51.3789 74.5176C51.7052 74.8529 52.0946 75.1192 52.5244 75.3011C52.9542 75.4829 53.4156 75.5766 53.8818 75.5766C54.348 75.5766 54.8094 75.4829 55.2392 75.3011C55.669 75.1192 56.0584 74.8529 56.3847 74.5176C56.711 74.1823 56.9676 73.7849 57.1395 73.3485C57.3113 72.9121 57.395 72.4456 57.3856 71.9763C57.3856 72.9119 57.7547 73.8092 58.4117 74.4708C59.0687 75.1324 59.9597 75.5041 60.8888 75.5041H63.5158'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M86.3538 58.4002C86.7851 58.4234 87.2077 58.532 87.5973 58.7197C87.987 58.9074 88.3361 59.1706 88.6248 59.4942C88.9134 59.8179 89.136 60.1956 89.2796 60.6058C89.4233 61.016 89.4853 61.4508 89.4621 61.8851L88.8842 72.6301C88.8646 73.0671 88.7593 73.4958 88.5742 73.8915C88.3892 74.2871 88.1282 74.642 87.8062 74.9355C87.4842 75.2291 87.1077 75.4555 86.6984 75.6016C86.289 75.7478 85.855 75.8108 85.4213 75.7871C84.9876 75.7634 84.5629 75.6534 84.1717 75.4635C83.7805 75.2736 83.4305 75.0075 83.142 74.6806C82.8534 74.3537 82.6321 73.9724 82.4907 73.5589C82.3493 73.1454 82.2906 72.7077 82.3181 72.2712L82.8961 61.5276C82.943 60.6513 83.3336 59.8295 83.982 59.2431C84.6304 58.6566 85.4835 58.3534 86.3538 58.4002Z'
			fill='#D9EDFF'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M82.9571 57.3669C83.7265 57.7799 84.3016 58.4837 84.5558 59.3236C84.8101 60.1634 84.7229 61.0706 84.3132 61.8457L80.3897 69.5362C80.1867 69.9199 79.9107 70.2597 79.5773 70.536C79.244 70.8123 78.8598 71.0198 78.4469 71.1466C78.0339 71.2734 77.6001 71.3171 77.1704 71.2751C76.7407 71.2331 76.3234 71.1062 75.9423 70.9018C75.5612 70.6974 75.2239 70.4194 74.9495 70.0837C74.6751 69.748 74.4691 69.3612 74.3431 68.9453C74.2172 68.5294 74.1739 68.0926 74.2156 67.6598C74.2573 67.2271 74.3832 66.8068 74.5862 66.4231L78.5096 58.7325C78.9198 57.9577 79.6186 57.3786 80.4526 57.1225C81.2866 56.8664 82.1874 56.9543 82.9571 57.3669Z'
			fill='#D9EDFF'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M118.784 63.37C118.836 63.3193 118.891 63.2716 118.948 63.227C114.474 59.3174 113.371 51.0348 104.765 46.5103C93.438 40.5529 79.4283 41.9744 67.1225 57.4498C66.8408 57.7866 66.6286 58.1766 66.4984 58.5971C66.3682 59.0175 66.3227 59.4598 66.3643 59.8982C66.406 60.3366 66.5341 60.7621 66.7412 61.15C66.9482 61.5379 67.2301 61.8803 67.5701 62.1572C67.9102 62.434 68.3017 62.6397 68.7218 62.7623C69.1418 62.8848 69.5819 62.9218 70.0162 62.8709C70.4506 62.8201 70.8706 62.6825 71.2515 62.4661C71.6324 62.2498 71.9666 61.9591 72.2345 61.6111C73.0506 60.5949 73.8704 59.6487 74.694 58.7726C74.9834 59.0838 75.3302 59.3354 75.715 59.5133C88.5901 69.3303 91.193 70.5772 94.3425 71.8113C99.2131 73.7175 101.363 74.6384 104.944 77.9031M96.6003 55.8811C95.5148 57.2039 94.0041 58.1026 92.3299 58.4218C90.6556 58.7409 88.9232 58.4603 87.4328 57.6286L79.8188 54.1222C84.2619 50.8475 88.7449 49.4804 93.2093 50.0667C97.0078 50.5586 97.8868 53.942 96.5946 55.8782L96.6003 55.8811Z'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M93.7477 56.5366L89.4622 50.4634L102.81 40.9153C103.169 40.6583 103.575 40.4751 104.005 40.3761C104.434 40.2772 104.879 40.2644 105.313 40.3385C105.747 40.4126 106.163 40.5721 106.536 40.808C106.909 41.0439 107.233 41.3515 107.488 41.7132L107.887 42.2781C108.142 42.6397 108.324 43.0485 108.422 43.4809C108.52 43.9134 108.533 44.3611 108.459 44.7986C108.386 45.236 108.227 45.6545 107.993 46.0303C107.759 46.406 107.454 46.7316 107.094 46.9885L93.7477 56.5366Z'
			fill='#FFC263'
		/>
		<path
			d='M93.7477 56.5366L89.4622 50.4634L102.81 40.9153C103.169 40.6583 103.575 40.4751 104.005 40.3761C104.434 40.2772 104.879 40.2644 105.313 40.3385C105.747 40.4126 106.163 40.5721 106.536 40.808C106.909 41.0439 107.233 41.3515 107.488 41.7132L107.887 42.2781C108.142 42.6397 108.324 43.0485 108.422 43.4809C108.52 43.9134 108.533 44.3611 108.459 44.7986C108.386 45.236 108.227 45.6545 107.993 46.0303C107.759 46.406 107.454 46.7316 107.094 46.9885L93.7477 56.5366Z'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M90.0457 47.0015L99.4276 40.2905C99.6891 40.0948 99.9872 39.9544 100.304 39.8778C100.621 39.8012 100.95 39.7899 101.271 39.8447C101.592 39.8995 101.899 40.0191 102.173 40.1965C102.447 40.3739 102.683 40.6053 102.865 40.8768'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M68.4758 67.5078L61.8856 72.2268C61.74 72.3289 61.6311 72.4757 61.5753 72.6453C61.5195 72.8148 61.5198 72.9981 61.5761 73.1675C61.6324 73.3369 61.7417 73.4834 61.8876 73.585C62.0335 73.6866 62.2082 73.7379 62.3854 73.7312C65.1488 73.6168 69.0978 73.112 69.0396 71.17L70.38 70.2119L68.4758 67.5078Z'
			fill='#FFC263'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M70.8601 70.8825L68.4802 67.5077C68.3541 67.3287 68.3037 67.1066 68.3402 66.8902C68.3767 66.6738 68.497 66.4808 68.6747 66.3537L72.0245 63.957L75.3572 68.676L71.9961 71.0713C71.8199 71.1959 71.6022 71.2459 71.3898 71.2106C71.1774 71.1753 70.9872 71.0575 70.8601 70.8825Z'
			fill='white'
		/>
		<path
			d='M70.8601 70.8825L68.4802 67.5077C68.3541 67.3287 68.3037 67.1066 68.3402 66.8902C68.3767 66.6738 68.497 66.4808 68.6747 66.3537L72.0245 63.957L75.3572 68.676L71.9961 71.0713C71.8199 71.1959 71.6022 71.2459 71.3898 71.2106C71.1774 71.1753 70.9872 71.0575 70.8601 70.8825Z'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M74.8772 68.0047L72.4972 64.6356C72.3721 64.4585 72.2829 64.2582 72.2347 64.0463C72.1864 63.8344 72.1801 63.6151 72.2161 63.4007C72.2521 63.1864 72.3297 62.9813 72.4445 62.7971C72.5592 62.613 72.7089 62.4535 72.8849 62.3276L89.462 50.4629L93.7461 56.5361L77.169 68.3965C76.993 68.5224 76.7941 68.6122 76.5837 68.6607C76.3732 68.7091 76.1553 68.7154 75.9425 68.679C75.7296 68.6426 75.526 68.5643 75.3432 68.4486C75.1604 68.3329 75.002 68.1821 74.8772 68.0047Z'
			fill='#FFC263'
		/>
		<path
			d='M74.8772 68.0047L72.4972 64.6356C72.3721 64.4585 72.2829 64.2582 72.2347 64.0463C72.1864 63.8344 72.1801 63.6151 72.2161 63.4007C72.2521 63.1864 72.3297 62.9813 72.4445 62.7971C72.5592 62.613 72.7089 62.4535 72.8849 62.3276L89.462 50.4629L93.7461 56.5361L77.169 68.3965C76.993 68.5224 76.7941 68.6122 76.5837 68.6607C76.3732 68.7091 76.1553 68.7154 75.9425 68.679C75.7296 68.6426 75.526 68.5643 75.3432 68.4486C75.1604 68.3329 75.002 68.1821 74.8772 68.0047Z'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M96.5947 55.8791C95.5099 57.201 94.0004 58.0994 92.3274 58.419C90.6544 58.7387 88.9231 58.4594 87.4329 57.6295L78.4443 53.4825C78.0505 53.2938 77.6234 53.1858 77.1877 53.1649C76.7521 53.144 76.3167 53.2105 75.9069 53.3607C75.4971 53.5108 75.121 53.7415 74.8005 54.0394C74.4801 54.3373 74.2216 54.6964 74.0403 55.0958C73.8589 55.4952 73.7583 55.9269 73.7442 56.3659C73.73 56.8048 73.8028 57.2422 73.9581 57.6526C74.1134 58.063 74.3482 58.4381 74.6489 58.7563C74.9495 59.0744 75.31 59.3291 75.7094 59.5056C88.5845 69.324 91.1874 70.5695 94.3369 71.8036'
			fill='white'
		/>
		<path
			d='M96.5947 55.8791C95.5099 57.201 94.0004 58.0994 92.3274 58.419C90.6544 58.7387 88.9231 58.4594 87.4329 57.6295L78.4443 53.4825C78.0505 53.2938 77.6234 53.1858 77.1877 53.1649C76.7521 53.144 76.3167 53.2105 75.9069 53.3607C75.4971 53.5108 75.121 53.7415 74.8005 54.0394C74.4801 54.3373 74.2216 54.6964 74.0403 55.0958C73.8589 55.4952 73.7583 55.9269 73.7442 56.3659C73.73 56.8048 73.8028 57.2422 73.9581 57.6526C74.1134 58.063 74.3482 58.4381 74.6489 58.7563C74.9495 59.0744 75.31 59.3291 75.7094 59.5056C88.5845 69.324 91.1874 70.5695 94.3369 71.8036'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M96.5947 55.8791C95.5099 57.201 94.0004 58.0994 92.3274 58.419C90.6544 58.7387 88.9231 58.4594 87.4329 57.6295L78.4443 53.4825C78.0505 53.2938 77.6234 53.1858 77.1877 53.1649C76.7521 53.144 76.3167 53.2105 75.9069 53.3607C75.4971 53.5108 75.121 53.7415 74.8005 54.0394C74.4801 54.3373 74.2216 54.6964 74.0403 55.0958C73.8589 55.4952 73.7583 55.9269 73.7442 56.3659C73.73 56.8048 73.8028 57.2422 73.9581 57.6526C74.1134 58.063 74.3482 58.4381 74.6489 58.7563C74.9495 59.0744 75.31 59.3291 75.7094 59.5056C88.5845 69.324 91.1874 70.5695 94.3369 71.8036'
			fill='#B06C49'
		/>
		<path
			d='M96.5947 55.8791C95.5099 57.201 94.0004 58.0994 92.3274 58.419C90.6544 58.7387 88.9231 58.4594 87.4329 57.6295L78.4443 53.4825C78.0505 53.2938 77.6234 53.1858 77.1877 53.1649C76.7521 53.144 76.3167 53.2105 75.9069 53.3607C75.4971 53.5108 75.121 53.7415 74.8005 54.0394C74.4801 54.3373 74.2216 54.6964 74.0403 55.0958C73.8589 55.4952 73.7583 55.9269 73.7442 56.3659C73.73 56.8048 73.8028 57.2422 73.9581 57.6526C74.1134 58.063 74.3482 58.4381 74.6489 58.7563C74.9495 59.0744 75.31 59.3291 75.7094 59.5056C88.5845 69.324 91.1874 70.5695 94.3369 71.8036'
			stroke='#020064'
			strokeWidth='0.24'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default function NewUserInvite() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = useRef(null);
	const [searchParams] = useSearchParams();

	const { newInviteObj } = useSelector((state) => state.auth);
	const { saving } = useSelector((state) => state.save);

	const token = searchParams.get('token');

	const [open, setopen] = useState(false);
	const [success, setsuccess] = useState(false);

	function handleCancel() {
		setopen(false);
		setsuccess(false);
	}

	async function handleInviteStatus() {
		if (newInviteObj?.mbiStatus === 'ACCEPT') {
			await customToast({
				content: 'You have already accepted this invite. Login to continue',
			});
            await dispatch(logout())
			await navigate('/login');
		} else if (newInviteObj?.mbiStatus === 'REJECT') {
			await customToast({
				content: 'This chama group invitation is no longer available.',
			});
            await dispatch(logout())
			await navigate('/login');
		}
	}

	async function handleFetch() {
		await dispatch(fetchNewUserInviteObj(token));
	}

	async function onFinish(data) {
		data.mbiInviteLink = token;
		data.mbiStatus = 'ACCEPT';
		data.url = applicationUrl().url;
		data.saveUrl = '/api/v1/reg/inviteAction';

		const res = await dispatch(save(data));        

		if (res?.payload?.success) {
		    setsuccess(true)
		} else {
			customToast({
				content: res?.payload?.messages?.message ?? 'An error occured',
				bdColor: 'error',
			});
		}
	}

	useEffect(() => {
		handleFetch();
	}, [token]);

	useEffect(() => {
		handleInviteStatus();
	}, [newInviteObj]);

	return (
		<>
			<AuthHeader />
			<div className='onboarding'>
				<div className='w-full flex justify-center items-center gap-[.94rem]'>
					<div
						style={{
							borderRadius: '0.875rem',
							background: '#FFF',
							boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						}}
						className='w-[41rem] border border-blkDark'>
						<div className='flex justify-center items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]'>
							<span className='heading_4 !text-white'>Chama invitation</span>
						</div>

						<div className='px-[2.94rem] py-[5rem]'>
							<div className='w-full flex flex-col  items-center'>
								{icon}
								<span className='heading_5 mt-[1.25rem]'>
									You’ve been invited to join {newInviteObj?.mbiCustName}
								</span>
								<span className='paragraph_2 text-center mt-[.625rem]'>
									Hi {newInviteObj?.mbiName}, you have received an invitation to
									join {newInviteObj?.mbiCustName}. <br />
									Accept or decline the invite below.
								</span>

								<Form
									layout='vertical'
									ref={formRef}
									name='newUserInvite'
									onFinish={onFinish}
									style={{
										maxWidth: '100%',
										marginTop: '3.75rem',
										width: '100%',
									}}
									form={form}>
									<div className='flex items-center justify-between mt-[5rem] w-full'>
										<div className='w-[8.25rem] flex'>
											<button
												disabled={saving}
												onClick={() => setopen(true)}
												className='decline_btn'
												type='button'>
												Decline
											</button>
										</div>

										<div className='w-[12.4375rem] flex items-center gap-[.5rem]'>
											<button
												disabled={saving}
												className='cstm-btn-2'
												type='submit'>
												{saving ? <Spin /> : 'Join'}
											</button>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>

			<DeclineInviteModal open={open} handleCancel={handleCancel} item={newInviteObj} />
			<NewUserSuccessModal open={success} handleCancel={handleCancel} item={newInviteObj} />
		</>
	);
}
