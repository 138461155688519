import { useSelector } from 'react-redux';
import Search from '../../components/Search';
import ChamaListCard from './cards/ChamListCard';
import { Link } from 'react-router-dom';
import useFetchDispatch from '../../custom_hooks/useFetchDispatch';
import { fetchChamas } from '../../features/fetch/fetchSlice';
import CardLoading from '../../components/CardLoading';

export default function ChamaList() {
	const { isLoading } = useFetchDispatch(fetchChamas);

	const { chamas } = useSelector((state) => state.fetch);

	if (isLoading) return <CardLoading />;

	return (
		<>
			<div className='flex flex-col w-full bg-white rounded-[1.25rem] h-full p-[2.5rem]'>
				{chamas?.length ? (
					<>
						<div className='w-[16.8125rem]'>
							<Search text={'Search chama'} />
						</div>

						<div className='flex items-center overflow-x-auto overflow-y-hidden h-full w-full mt-[1.25rem] gap-[.44rem]'>
							{chamas?.length
								? chamas?.map((item, index) => {
										return (
											<div key={index}>
												<ChamaListCard item={item} />
											</div>
										);
								  })
								: null}
						</div>
					</>
				) : (
					<>
						<div className='flex flex-col justify-center items-center h-full w-full mt-[1.25rem] gap-[.44rem]'>
							<div className='w-fit'>
								<button className='cstm-btn'>Join an existing chama</button>

								<Link to='/new/chama-features' className='cstm-btn-2 mt-[1rem]'>
									Create a chama
								</Link>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}
