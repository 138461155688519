import { Checkbox } from 'antd';

export default function ChamaFeatureCard({
	icon,
	header,
	content,
	cardKey,
	activeOption,
	handleActiveOption,
}) {
	return (
		<>
			<button
				onClick={() => handleActiveOption(cardKey)}
				style={{
					background: 'white',
					border:
						cardKey === activeOption?.includes(cardKey) ||
						String(header)?.toUpperCase()?.includes('SAV')
							? '1px solid var(--Ndovu-Black, #121212)'
							: '1px solid var(--Tile-stroke, #E2E9EE)',
				}}
				className='w-[12.25rem] max-w-full h-[13.625rem] p-[1rem] rounded-[0.75rem] flex flex-col justify-center items-center'>
				<div className='flex justify-center items-center w-full'>
					<div className='flex justify-center w-full'>
						<img src={icon} alt='icon' />
					</div>

					{String(header)?.toUpperCase()?.includes('SAV') ? (
						<>
							<div></div>
						</>
					) : (
						<div className='flex justify-end'>
							<Checkbox checked={activeOption?.includes(cardKey)} className='alt_checkbox' />
						</div>
					)}
				</div>
				<span className='label_2 text-center mt-[2.5rem]'>{header}</span>
				<span className='paragraph_3 text-center mt-[1.25rem]'>{content}</span>
			</button>
		</>
	);
}
