import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../../layout/BreadCrumb';
import PageActions from '../../components/PageActions';
import Search from '../../components/Search';
import StatementFilter from '../statements/StatementFilter';
import GroupActivitySummaryCard from './cards/GroupActivitySummaryCard';
import AnalyticGraph from './graphs/AnalyticGraph';
import GroupContributionTable from './tables/GroupContributionTable';
import AvailableBalanceCard from './cards/AvailbaleBalanceCard';
import { Link } from 'react-router-dom';
import MembersTable from '../members/tables/MembersTable';
import useToggle from '../../custom_hooks/useToggle';
import useFetchDispatch from '../../custom_hooks/useFetchDispatch';
import { fetchGroupCardsSummary } from '../../features/money/moneySlice';
import { capitalize, formatMoney } from '../../utils';

export default function GroupOverview() {
	const { isLoading } = useFetchDispatch(fetchGroupCardsSummary);

	const { groupCardSummary } = useSelector((state) => state.money);
	const { activeChama } = useSelector((state) => state.obj);

	const { toggle, show } = useToggle();

	const colors = ['rgba(147, 205, 147, 0.14)', '#FDFFE9', '#F3EBFF'];

	const pageActions = [];

	const balanceObj = [
		{
			header: 'Investments',
			amount: formatMoney(groupCardSummary?.availableBalance?.investment, 'KES'),
		},
		{
			header: 'Merry-go-round',
			amount: formatMoney(groupCardSummary?.availableBalance?.merryGoRound, 'KES'),
			hasFooter: true,
			footer: '10% of each contribution',
		},
		{
			header: 'Lending balance',
			amount: formatMoney(groupCardSummary?.availableBalance?.leadingBalance, 'KES'),
			hasFooter: true,
			footer: '5% of each contribution',
		},
	];

	const breadList = [
		{
			title: (
				<div className='flex items-center gap-[.25rem]'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='14'
						height='14'
						viewBox='0 0 14 14'
						fill='none'>
						<g clipPath='url(#clip0_627_13942)'>
							<path
								d='M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z'
								fill='black'
								fillOpacity='0.45'
							/>
						</g>
						<defs>
							<clipPath id='clip0_627_13942'>
								<rect width='14' height='14' fill='white' />
							</clipPath>
						</defs>
					</svg>
					<span>Home</span>
				</div>
			),
			href: '/#/home',
		},
		{
			title: capitalize(activeChama?.custName),
		},
	];

	return (
		<>
			<div className='grid grid-cols-1 gap-[1.25rem] pb-[5rem]'>
				<div className='justify-between items-center white_card'>
					<BreadCrumb
						breadList={breadList}
						header={'Group activity'}
						info={'Here’s an overview of the group activity'}
					/>
					<PageActions pageActions={pageActions} />
				</div>

				<div className='white_card flex-col'>
					<span className='heading_4'>Summary</span>

					<div className='flex w-full items-center overflow-x-auto overflow-y-hidden gap-[.75rem] mt-[1.69rem]'>
						<GroupActivitySummaryCard
							link='/members/balance'
							header={'Opening members balance'}
							amount={formatMoney(groupCardSummary?.openingBalance, 'KES')}
						/>
						<GroupActivitySummaryCard
							link={'/group-activity/statements'}
							header={'Ndovu chama collection'}
							amount={formatMoney(groupCardSummary?.collection, 'KES')}
						/>
						<GroupActivitySummaryCard
							link={'/members/balance'}
							header={'Total members balance'}
							amount={formatMoney(groupCardSummary?.memberBalance, 'KES')}
							hasOtherIcon={true}
						/>
						<GroupActivitySummaryCard
							link={'/group-activity/statements'}
							header={'Withdrawals'}
							amount={formatMoney(groupCardSummary?.withdrawal, 'KES')}
							hasPointerIcon={true}
						/>
						<GroupActivitySummaryCard
							toggle={true}
							handleToggleBalance={toggle}
							header={'Available balance'}
							amount={formatMoney(
								groupCardSummary?.availableBalance?.totalAvailableBalance,
								'KES'
							)}
							hasIcon={true}
							showBalance={show}
						/>
						<GroupActivitySummaryCard
							header={'Total Loans'}
							amount={formatMoney(groupCardSummary?.totalLoans, 'KES')}
							hasLoanIcon={true}
						/>
						<GroupActivitySummaryCard
							header={'Active Loans'}
							amount={groupCardSummary?.activeLoans}
						/>
					</div>

					{show ? (
						<>
							<div className='flex items-center gap-[1.25rem] mt-[1.25rem]'>
								{balanceObj.map((item, index) => {
									return (
										<div className='min-w-[11.5625rem] w-fit' key={index}>
											<AvailableBalanceCard
												header={item.header}
												footer={item.footer}
												hasFooter={item.hasFooter}
												amount={item.amount}
												background={colors[index]}
											/>
										</div>
									);
								})}
							</div>
						</>
					) : null}
				</div>

				<div className='white_card flex-col'>
					<AnalyticGraph />
				</div>

				<div className='white_card flex-col'>
					<div className='w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]'>
						<div className='flex justify-between items-center'>
							<span className='heading_4'>Group contributions</span>
							<div className='flex items-center gap-[1rem]'>
								<Link
									to='/group/contribution-history'
									className='view_more_text'>
									View more
								</Link>
								<div
									style={{
										boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
										background: '#fff',
									}}
									className='w-[1.5rem] h-[1.5rem] p-[.25rem] flex justify-center items-center rounded-full'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16'
										height='16'
										viewBox='0 0 16 16'
										fill='none'>
										<path
											d='M3.85336 13.354C3.8069 13.4005 3.75175 13.4373 3.69106 13.4625C3.63036 13.4876 3.5653 13.5006 3.49961 13.5006C3.43391 13.5006 3.36886 13.4876 3.30816 13.4625C3.24746 13.4373 3.19231 13.4005 3.14586 13.354C3.0994 13.3076 3.06255 13.2524 3.03741 13.1917C3.01227 13.131 2.99933 13.066 2.99933 13.0003C2.99933 12.9346 3.01227 12.8695 3.03741 12.8088C3.06255 12.7481 3.0994 12.693 3.14586 12.6465L7.79273 8.00028L3.14586 3.35403C3.05204 3.26021 2.99933 3.13296 2.99933 3.00028C2.99933 2.8676 3.05204 2.74035 3.14586 2.64653C3.23968 2.55271 3.36693 2.5 3.49961 2.5C3.63229 2.5 3.75954 2.55271 3.85336 2.64653L8.85336 7.64653C8.89985 7.69296 8.93672 7.74811 8.96189 7.80881C8.98705 7.86951 9 7.93457 9 8.00028C9 8.06599 8.98705 8.13105 8.96189 8.19175C8.93672 8.25245 8.89985 8.30759 8.85336 8.35403L3.85336 13.354ZM8.14586 3.35403L12.7927 8.00028L8.14586 12.6465C8.0994 12.693 8.06255 12.7481 8.03741 12.8088C8.01227 12.8695 7.99933 12.9346 7.99933 13.0003C7.99933 13.066 8.01227 13.131 8.03741 13.1917C8.06255 13.2524 8.0994 13.3076 8.14586 13.354C8.19231 13.4005 8.24746 13.4373 8.30816 13.4625C8.36885 13.4876 8.43391 13.5006 8.49961 13.5006C8.5653 13.5006 8.63036 13.4876 8.69105 13.4625C8.75175 13.4373 8.8069 13.4005 8.85336 13.354L13.8534 8.35403C13.8998 8.30759 13.9367 8.25245 13.9619 8.19175C13.987 8.13105 14 8.06599 14 8.00028C14 7.93457 13.987 7.86951 13.9619 7.80881C13.9367 7.74811 13.8998 7.69296 13.8534 7.64653L8.85336 2.64653C8.75954 2.55271 8.63229 2.5 8.49961 2.5C8.36692 2.5 8.23968 2.55271 8.14586 2.64653C8.05204 2.74035 7.99933 2.8676 7.99933 3.00028C7.99933 3.13296 8.05204 3.26021 8.14586 3.35403Z'
											fill='#343330'
										/>
									</svg>
								</div>
							</div>
						</div>
						<div className='flex items-center gap-[1rem] w-full'>
							<div className='w-[13.625rem] '>
								<Search text={'Search'} />
							</div>

							<div className='w-[12.5rem]'>
								<StatementFilter />
							</div>
						</div>

						<GroupContributionTable />
					</div>
				</div>

				<div className='white_card flex-col'>
					<div className='w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]'>
						<div className='flex justify-between items-center'>
							<span className='heading_4'>Group members</span>
							<div className='flex items-center gap-[1rem]'>
								<Link to='/members/overview' className='view_more_text'>
									View more
								</Link>
								<div
									style={{
										boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
										background: '#fff',
									}}
									className='w-[1.5rem] h-[1.5rem] p-[.25rem] flex justify-center items-center rounded-full'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16'
										height='16'
										viewBox='0 0 16 16'
										fill='none'>
										<path
											d='M3.85336 13.354C3.8069 13.4005 3.75175 13.4373 3.69106 13.4625C3.63036 13.4876 3.5653 13.5006 3.49961 13.5006C3.43391 13.5006 3.36886 13.4876 3.30816 13.4625C3.24746 13.4373 3.19231 13.4005 3.14586 13.354C3.0994 13.3076 3.06255 13.2524 3.03741 13.1917C3.01227 13.131 2.99933 13.066 2.99933 13.0003C2.99933 12.9346 3.01227 12.8695 3.03741 12.8088C3.06255 12.7481 3.0994 12.693 3.14586 12.6465L7.79273 8.00028L3.14586 3.35403C3.05204 3.26021 2.99933 3.13296 2.99933 3.00028C2.99933 2.8676 3.05204 2.74035 3.14586 2.64653C3.23968 2.55271 3.36693 2.5 3.49961 2.5C3.63229 2.5 3.75954 2.55271 3.85336 2.64653L8.85336 7.64653C8.89985 7.69296 8.93672 7.74811 8.96189 7.80881C8.98705 7.86951 9 7.93457 9 8.00028C9 8.06599 8.98705 8.13105 8.96189 8.19175C8.93672 8.25245 8.89985 8.30759 8.85336 8.35403L3.85336 13.354ZM8.14586 3.35403L12.7927 8.00028L8.14586 12.6465C8.0994 12.693 8.06255 12.7481 8.03741 12.8088C8.01227 12.8695 7.99933 12.9346 7.99933 13.0003C7.99933 13.066 8.01227 13.131 8.03741 13.1917C8.06255 13.2524 8.0994 13.3076 8.14586 13.354C8.19231 13.4005 8.24746 13.4373 8.30816 13.4625C8.36885 13.4876 8.43391 13.5006 8.49961 13.5006C8.5653 13.5006 8.63036 13.4876 8.69105 13.4625C8.75175 13.4373 8.8069 13.4005 8.85336 13.354L13.8534 8.35403C13.8998 8.30759 13.9367 8.25245 13.9619 8.19175C13.987 8.13105 14 8.06599 14 8.00028C14 7.93457 13.987 7.86951 13.9619 7.80881C13.9367 7.74811 13.8998 7.69296 13.8534 7.64653L8.85336 2.64653C8.75954 2.55271 8.63229 2.5 8.49961 2.5C8.36692 2.5 8.23968 2.55271 8.14586 2.64653C8.05204 2.74035 7.99933 2.8676 7.99933 3.00028C7.99933 3.13296 8.05204 3.26021 8.14586 3.35403Z'
											fill='#343330'
										/>
									</svg>
								</div>
							</div>
						</div>
						<div className='flex items-center gap-[1rem] w-full'>
							<div className='w-[13.625rem] '>
								<Search text={'Search'} />
							</div>

							<div className='w-[12.5rem]'>
								<StatementFilter />
							</div>
						</div>

						<MembersTable />
					</div>
				</div>
			</div>
		</>
	);
}
