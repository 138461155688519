import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from './layout/SideBar';
import SubHeader from './layout/SubHeader';
import axiosInstance from './instance';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { handleUser } from './features/auth/authSlice';

export const ProtectedRoute = () => {
	const dispatch = useDispatch();

	const { isActive } = useSelector((state) => state.auth);

	const token = localStorage.getItem('token');

	axiosInstance.defaults.headers.common = {'Authorization': `Bearer ${token}`}

	async function handleInit() {
		if (token) {
			await handleDecodeToken();
		}
	}

	async function handleDecodeToken() {
		try {
			let tokenObj = jwtDecode(token);
			await dispatch(handleUser(tokenObj));
		} catch (error) {
			await dispatch(handleUser({}));
		}
	}

	useEffect(() => {
		axiosInstance.defaults.headers.common = {'Authorization': `Bearer ${token}`}
		handleInit();
	}, [token, isActive]);

	if (!isActive && !token) {
		return <Navigate to='/login' replace />;
	}

	return (
		<>
			<div className='flex flex-col w-full'>
				<div className='sticky top-0 z-[10]'>
					<SubHeader />
				</div>

				<div className='flex items-start justify-start w-full h-full pt-[1.5rem] pl-[1rem] pr-[4.88rem]'>
					<div className='flex h-full sticky top-0'>
						<SideBar />
					</div>

					<div className='pl-[2.5rem] w-full h-full'>
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
};
