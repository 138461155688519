import Search from '../../../components/Search';
import StatementFilter from '../StatementFilter';
import WithdrawalStatementTable from '../tables/WithdrawalStatementTable';


export default function WithdrawalStatementTab({ data }) {

	return (
		<>
			<div className='grid grid-cols-1 gap-[1.25rem]'>

				<div className='white_card flex-col'>
				<span className='heading_4 mt-[1rem]'>Statements (Withdrawals)</span>
					<div className='w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]'>
                    <div className='flex justify-between items-center'>
							<div className='flex items-center gap-[1rem] w-full'>
								<div className='w-[13.625rem] '>
									<Search text={'Search'} />
								</div>

								<div className='w-[12.5rem]'>
									<StatementFilter />
								</div>
							</div>
							<div className='flex w-auto'>
								<button type='button' className='cstm-btn-2 !gap-[.25rem]'>
									<svg
										className='flex flex-shrink-0'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='25'
										viewBox='0 0 24 25'
										fill='none'>
										<path
											d='M11.65 18.45L15.35 11.25H12.5V6.5L8.675 13.75H11.65V18.45ZM12 22C10.6833 22 9.446 21.75 8.288 21.25C7.12933 20.75 6.125 20.075 5.275 19.225C4.425 18.375 3.75 17.3707 3.25 16.212C2.75 15.054 2.5 13.8167 2.5 12.5C2.5 11.1833 2.75 9.94567 3.25 8.787C3.75 7.629 4.425 6.625 5.275 5.775C6.125 4.925 7.12933 4.25 8.288 3.75C9.446 3.25 10.6833 3 12 3C13.3167 3 14.5543 3.25 15.713 3.75C16.871 4.25 17.875 4.925 18.725 5.775C19.575 6.625 20.25 7.629 20.75 8.787C21.25 9.94567 21.5 11.1833 21.5 12.5C21.5 13.8167 21.25 15.054 20.75 16.212C20.25 17.3707 19.575 18.375 18.725 19.225C17.875 20.075 16.871 20.75 15.713 21.25C14.5543 21.75 13.3167 22 12 22ZM12 20.5C14.2333 20.5 16.125 19.725 17.675 18.175C19.225 16.625 20 14.7333 20 12.5C20 10.2667 19.225 8.375 17.675 6.825C16.125 5.275 14.2333 4.5 12 4.5C9.76667 4.5 7.875 5.275 6.325 6.825C4.775 8.375 4 10.2667 4 12.5C4 14.7333 4.775 16.625 6.325 18.175C7.875 19.725 9.76667 20.5 12 20.5Z'
											fill='#FFCD47'
										/>
									</svg>
									<span className='flex flex-shrink-0'>Export to pdf</span>
								</button>
							</div>
						</div>

						<WithdrawalStatementTable data={data} />
					</div>
				</div>
			</div>
		</>
	);
}
