import piggyBank from '../../assets/svg/PiggyBank.svg';
import circlesFour from '../../assets/svg/CirclesFour.svg';
import handCoins from '../../assets/svg/HandCoins.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChamaFeatures } from '../../features/options/optionSLice';
import ChamaFeatureCard from './cards/ChamaFeatureCard';
import { clearObj, handleChamaFeaturesArray } from '../../features/obj/objSlice';

export default function ChamaFeatures() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { chamaFeatures } = useSelector((state) => state.option);
	const { chamaFeaturesArray } = useSelector(
		(state) => state.obj
	);

	const [activeOption, setactiveOption] = useState(chamaFeaturesArray ?? []);

	function handleActiveOption(item) {
		if (activeOption.includes(item)) {
			let newOptions = activeOption.filter((x) => x !== item);
			setactiveOption(newOptions);
		} else {
			setactiveOption([...activeOption, item]);
		}
	}

	async function handleOptionNavigation() {
		let savings = featuresArray?.find((item) =>
			String(item?.header).toUpperCase().includes('SAV')
		);
		console.log(savings.key);
		let chamaFeaturesArray = [...activeOption, savings.key];

        await dispatch(handleChamaFeaturesArray(chamaFeaturesArray))
		await navigate('/new/chama-group');
	}

	async function handleFetch() {
		await dispatch(fetchChamaFeatures());
		await dispatch(clearObj())
	}

	const cardOptionData = [
		{
			key: 1,
			icon: piggyBank,
			header: 'Savings',
			content:
				'Grow your finances by saving as a group. This feature is enabled by default.',
		},
		{
			key: 2,
			icon: circlesFour,
			header: 'Merry-go-round',
			content:
				'Contribute regularly and take turns receiving the total amount.',
		},
		{
			key: 3,
			icon: handCoins,
			header: 'Loans',
			content:
				'Contribute together to a fund that offers loans when members need them.',
		},
	];

	const featuresArray = cardOptionData
		.map((cardItem) => {
			const matchingFeature = chamaFeatures?.find(
				(feature) => feature.csfName === cardItem.header
			);

			if (matchingFeature) {
				return {
					key: matchingFeature?.csfId,
					icon: cardItem.icon,
					header: matchingFeature?.csfName,
					content: cardItem.content,
				};
			}

			return null;
		})
		.filter(Boolean);

	useEffect(() => {
		handleFetch();
	}, []);

	return (
		<>
			<div className='onboarding'>
				<span className='heading_1'>Choose group features</span>
				<span className='paragraph_1 mt-[1.5rem]'>
					What additional features would you like your group to have? <br />
					You can have more than one feature.
				</span>

				<div className='grid grid-cols-1 lg:grid-cols-3 gap-[.94rem] mt-[2.5rem]'>
					{featuresArray?.map((item, index) => {
						return (
							<div key={index}>
								<ChamaFeatureCard
									handleActiveOption={handleActiveOption}
									cardKey={item.key}
									header={item.header}
									icon={item.icon}
									content={item.content}
									activeOption={activeOption}
								/>
							</div>
						);
					})}
				</div>

				<div className='w-[10.625rem] mt-[5rem] flex flex-col gap-[.75rem]'>
					<button onClick={handleOptionNavigation} className='cstm-btn-3'>
						Next
					</button>

					<button onClick={() => navigate('/onboarding')} className='cstm-btn'>
						Previous
					</button>
				</div>
			</div>
		</>
	);
}
