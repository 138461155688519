import { DatePicker, Form, Input, Spin } from 'antd';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import RegisterOTPModal from '../modals/RegisterOTPModal';
import FileUploader from '../../../components/FileUploader';
import { useDispatch, useSelector } from 'react-redux';
import {
	handleAuthData,
	register,
	verifyAuthDraftUser,
} from '../../../features/auth/authSlice';
import { customToast } from '../../../utils';

export default function PersonalDetailsStep({ handleNext, form }) {
	const dispatch = useDispatch();

	const { authLoading, authData } = useSelector((state) => state.auth);

	const [open, setopen] = useState(false);
	const [kraFile, setkraFile] = useState(authData?.usrKraPinFile ?? '');

	function handleCancel() {
		setopen(false);
	}

	async function handleSubmitStep() {
		try {
			await form.validateFields([
				'usrFirstName',
				'usrLastName',
				'usrMobileNumber',
			]);
			let personalData = form.getFieldsValue();

			personalData.usrKraPinFile = kraFile;
			personalData.usrId = authData?.usrId;

			const res = await dispatch(register(personalData));
			
			if (res?.payload?.success) {
				await dispatch(handleAuthData(res?.payload?.data?.result));
				await setopen(true);
			} else {
				customToast({
					content: res?.payload?.messages?.message,
					bdColor: 'error',
				});
			}
		} catch {}
	}

	// async function handleFetchAuthData(phone) {
	// 	console.log(phone);
		
	// 	await dispatch(
	// 		verifyAuthDraftUser({
	// 			usrMobileNumber: phone,
	// 			usrFirstName: authData?.usrFirstName,
	// 		})
	// 	);
	// }

	const handleSuccess = (response) => {
		setkraFile(response?.data?.result);
	};

	return (
		<>
			<div className='flex flex-col w-full'>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-[.5rem]'>
					<Form.Item
						label='First name'
						name='usrFirstName'
						rules={[
							{
								required: true,
								message: 'First name is required',
							},
						]}>
						<Input className='input' />
					</Form.Item>

					<Form.Item
						label='Last name'
						name='usrLastName'
						rules={[
							{
								required: true,
								message: 'Last name is required',
							},
						]}>
						<Input className='input' />
					</Form.Item>
				</div>

				<Form.Item label='Date of birth' name='usrDob'>
					<DatePicker
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='22'
								height='23'
								viewBox='0 0 22 23'
								fill='none'>
								<path
									d='M17.875 3.52881H4.125C3.7453 3.52881 3.4375 3.83661 3.4375 4.21631V17.9663C3.4375 18.346 3.7453 18.6538 4.125 18.6538H17.875C18.2547 18.6538 18.5625 18.346 18.5625 17.9663V4.21631C18.5625 3.83661 18.2547 3.52881 17.875 3.52881Z'
									stroke='#343330'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M15.125 2.15381V4.90381'
									stroke='#343330'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M6.875 2.15381V4.90381'
									stroke='#343330'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M3.4375 7.65381H18.5625'
									stroke='#343330'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						}
						className='input'
						placeholder=''
					/>
				</Form.Item>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-[.5rem]'>
					<Form.Item label='KRA PIN' name='usrKraPin'>
						<Input className='input' />
					</Form.Item>

					<Form.Item label='Upload KRA PIN'>
						<FileUploader
							fileName={kraFile?.split('_').pop()}
							name='usrKraPinFile'
							onSuccess={handleSuccess}
						/>
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-[.5rem]'>
					<Form.Item
						label='Email'
						name='usrEmail'
						rules={[
							{
								required: false,
								message: 'Please input your email',
							},
						]}>
						<Input className='input' type='email' />
					</Form.Item>

					<Form.Item
						label='Phone number'
						name='usrMobileNumber'
						rules={[
							{
								required: true,
								message: 'Phone number is required',
							},
						]}>
						<PhoneInput
							enableSearch
							country='ke'
							countryCodeEditable={false}
							className='input'
							// onChange={(val) => handleFetchAuthData(val)}
						/>
					</Form.Item>
				</div>

				<div className='py-7 w-full flex justify-center'>
					<button
						disabled={authLoading}
						onClick={handleSubmitStep}
						type='button'
						className='cstm-btn-2 !w-[200px]'>
						{authLoading ? <Spin /> : 'Next'}
					</button>
				</div>
			</div>

			<RegisterOTPModal
				handleNext={handleNext}
				open={open}
				handleCancel={handleCancel}
			/>
		</>
	);
}
