import { useNavigate } from 'react-router-dom';
import OnboardingCard from './cards/OnboardingCard';
import { useSelector } from 'react-redux';
import { getFistName } from '../../utils';

export default function Onboarding() {
	const navigate = useNavigate();

	const { user } = useSelector((state) => state.auth);

	const cardData = [
		{
			key: 1,
			background: '#8EC68E',
			color: false,
			content: 'Save towards your goals',
		},
		{
			key: 2,
			background: '#222',
			color: false,
			content: 'Manage group investments',
		},
		{
			key: 3,
			background: '#C5D5FF',
			color: true,
			content: 'Enable merry-go-round',
		},
		{
			key: 4,
			background: '#222',
			color: false,
			content: 'Manage group loans',
		},
		{
			key: 5,
			background: '#8EC68E',
			color: false,
			content: 'Track progress with reports',
		},
	];
	return (
		<>
			<div className='onboarding'>
				<span className='heading_1'>Hi {getFistName(user?.username)}, welcome to Ndovu chama.</span>
				<span className='paragraph_1 mt-[1.5rem]'>
					The group savings app that does it all.
				</span>

				<div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[1rem] mt-[2.5rem]'>
					{cardData.map((item, index) => {
						return (
							<div key={index}>
								<OnboardingCard
									background={item.background}
									color={item.color}
									content={item.content}
								/>
							</div>
						);
					})}
				</div>

				<div className='w-[10.625rem] mt-[5rem]'>
					<button
						onClick={() => navigate('/onboarding-options')}
						className='cstm-btn-3'>
						Next
					</button>
				</div>
			</div>
		</>
	);
}
